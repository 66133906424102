import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAddress {
  address_line_1?: string;
  address_line_2?: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
}

export interface IUserData {
  id: string;
  email: string;
  username: string;
  role: "USER";
  is_email_verified: boolean;
  subscription: "BASIC";
  activation_date: string;
  valid_till: string;
  is_paid: boolean;
  img: string;
  phone?: string;
  is_trade_support: boolean;
  addresses: IAddress;
  session_token?: string;
  uid?: string;
}

export interface User {
  isAuth: boolean;
  token: string | null;
  isEditMode?: boolean;
  data: IUserData | null;
  isLoginModalOpen?: boolean;
}

export const initialUserState: User = {
  isAuth: false,
  token: null,
  isEditMode: false,
  data: null,
  isLoginModalOpen: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    updateLogin: (state, action: PayloadAction<User>) => {
      state.isAuth = action.payload.isAuth;
      state.token = action.payload.token;
      state.data = action.payload.data;
    },
    updateLogout: (state) => {
      state.isAuth = false;
      state.token = null;
      state.data = null;
    },
    updateUserEditMode: (state, action: PayloadAction<boolean>) => {
      state.isEditMode = action.payload;
    },
    updateUserData: (state, action: PayloadAction<IUserData>) => {
      state.data = action.payload;
    },

    // ? update user data phone number
    updateUserDataPhone: (state, action: PayloadAction<string>) => {
      if (state.data) {
        state.data.phone = action.payload;
      }
    },

    // ? update user data address
    updateUserDataAddress: (state, action: PayloadAction<IAddress>) => {
      if (state.data) {
        state.data.addresses = action.payload;
      }
    },

    // ? update user name
    updateUserDataName: (state, action: PayloadAction<string>) => {
      if (state.data) {
        state.data.username = action.payload;
      }
    },

    // ? open close login modal
    handleChangeLoginModal: (state, action: PayloadAction<boolean>) => {
      state.isLoginModalOpen = action.payload;
    },
  },
});

export const {
  updateLogin,
  updateLogout,
  updateUserEditMode,
  updateUserData,
  updateUserDataAddress,
  updateUserDataName,
  updateUserDataPhone,
  handleChangeLoginModal,
} = userSlice.actions;

export default userSlice.reducer;
