import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { IoMdPerson } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa6";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { GiDuration } from "react-icons/gi";
import { AiFillProject } from "react-icons/ai";
import { PiCertificateBold, PiCurrencyInrBold } from "react-icons/pi";
import CartTotal from "./cartTotal";
import {
  removeFromCart,
  updateCurrentButton,
  updateItem,
} from "../../redux/slices/program";

export default function CartComponents() {
  const cart = useAppSelector((state: any) => state?.program.cart);
  const dispatch = useAppDispatch();

  const [hover, setHover] = React.useState<any>(-1);
  const [lmsData, setLmsDta] = React.useState<any>(cart);
  useEffect(() => {
    setLmsDta(cart);
  }, [cart]);
  const navigate = useNavigate();

  const labels: any = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  function getLabelText(value: any) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  const renderContainer = () => {
    const containers: any = [];

    lmsData.map((item: any, i: any) => {
      // Added parentheses here
      containers.push(
        <div key={i} className=" bg-white p-4 ">
          {i == 0 && (
            <div className="  ">
              <div className=" flex flex-row justify-between   w-[806px]">
                <div className="font-poppins  text-3xl font-normal leading-15 text-left">
                  Cart
                </div>
                <div className="font-poppins  text-xl font-normal leading-15 text-left">
                  {lmsData.length} Course in Cart
                </div>
              </div>
              <div className="border-solid my-2 bg-[#6A6B6C] h-[1px] "></div>
            </div>
          )}
          <div className="flex flex-row  justify-between h-[144px]   w-[806px]">
            <button
              onClick={() => {
                dispatch(updateItem(item));
                navigate("/details");
              }}
              className="relative  h-[154px] w-[472px] rounded-2xl flex flex-col justify-start"
            >
              <div
                onClick={() => {
                  navigate("/details");
                }}
                className="flex flex-row justify-center"
              ></div>

              <div className="font-poppins  text-xl font-semibold leading-15 text-left">
                {item.courserName}
              </div>

              <div className="font-poppins   text-xl font-light leading-15 text-left">
                {item.mentor}
              </div>
              <div className="h-[56px] flex flex-row justify-between rounded-md">
                <div>
                  <div className="flex felx-row  justify-start items-center ">
                    <IoMdPerson color="#6A6B6C" />
                    <div className="font-poppins   text-sm font-normal leading-15 text-left text-[#827A7A]">
                      {item.people} Student
                    </div>
                  </div>
                  <div className="flex items-center ">
                    <div className="font-poppins text-lg font-bold leading-15 text-[#827A7A]">
                      {item.rating}
                    </div>
                    <div className="items-center justify-center pt-2 ">
                      <Rating
                        size="small"
                        name="hover-feedback"
                        value={item.rating}
                        precision={0.5}
                        getLabelText={getLabelText}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex felx-row  justify-start items-center ">
                    <GiDuration color="#6A6B6C" />
                    <div className="font-poppins   text-sm font-normal leading-15 text-left text-[#186F65]">
                      {item.duration}
                    </div>
                  </div>
                  <div className="flex items-center pt-3">
                    <div className="font-poppins text-xs bg-[#B5CB99] bg-opacity-30  rounded-full py-1 px-4 font-medium leading-15 text-[#86F65]">
                      {item.lession} lession
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex felx-row  justify-start items-center ">
                    <AiFillProject color="#6A6B6C" />
                    <div className="font-poppins   text-sm font-normal leading-15 text-left text-[#186F65]">
                      {item.projectKit}
                    </div>
                  </div>
                  <div className="flex items-center pt-3">
                    <div className="font-poppins text-xs bg-[#B5CB99] bg-opacity-30  rounded-full py-1 px-4 font-medium leading-15 text-[#86F65]">
                      Project kit
                    </div>
                  </div>
                </div>
              </div>
            </button>

            <div>
              <div
                className="flex felx-row items-center
              justify-center pt-14"
              >
                <button
                  onClick={() => {
                    dispatch(removeFromCart(i));
                  }}
                  className="font-poppins text-sm font-normal leading-15 text-left text-[#000000]"
                >
                  Remove
                </button>
              </div>
            </div>
            <div>
              <div
                className="flex felx-row items-center
              justify-center pt-3"
              >
                <PiCurrencyInrBold size={20} color="#186F65" />

                <div className="font-poppins text-xl font-bold leading-15 text-left text-[#186F65]">
                  {item.price}
                </div>
              </div>
              <div
                className="flex felx-row items-center
              justify-center pt-3"
              >
                <PiCurrencyInrBold size={15} color="#000000" opacity={0.3} />

                <div className="font-poppins text-sm font-bold leading-15 text-left text-[#000000] text-opacity-30 line-through">
                  {item.discount}
                </div>
              </div>
            </div>
          </div>

          <div className=" mb-4 border-solid bg-[#6A6B6C] h-[1px] "></div>
        </div>
      );
    });

    return containers;
  };

  return (
    <div className=" bg-white rounded-md py-2 mt-20  mx-20">
      <div className="flex flex-row justify-between ">
        {lmsData.length == 0 ? (
          <button
            onClick={() => {
              // dispatch(updateCurrentButton("Courses"));
              navigate("/courses");
            }}
            className="p-40  items-center justify-center font-poppins text-xl font-bold leading-15 text-left text-[#186F65]"
          >
            No Program in The Cart go to Courses
          </button>
        ) : (
          <div className="flex flex-col ">{renderContainer()}</div>
        )}

        <CartTotal />
      </div>
    </div>
  );
}
