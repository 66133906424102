import React from "react";
import DetailPage from "./detailPage/detail";
import EnrollTopHeader from "../enroll/enrollComponents/enrollHeader";
import DetailAgenda from "./detailPage/detailAgenda";
import DetailContent from "./detailPage/detailContect";

export default function Details() {
  return (
    <div className="bg-[#FFFAED]">
      <EnrollTopHeader />

      <div className="bg-[#FEEBD3] pt-20">
        <DetailPage />
      </div>

      <div className="flex flex-row justify-between px-20">
        <div className="felx-1">
          <DetailContent />
        </div>
        <div className="felx-1">
          <DetailAgenda />
        </div>
      </div>
    </div>
  );
}
