import React, { useState } from "react";
import TopHeader from "./dashboardComponents/topHeader";
import TopNavBar from "./dashboardComponents/topNavBar";
import TopBody from "./dashboardComponents/topBody";
import MidBody from "./dashboardComponents/midBody";
import BottomBody from "./dashboardComponents/bottomBody";
import PartnerView from "./dashboardComponents/partner";
import TopBodyStrength from "./dashboardComponents/strength";
import MidBodyFeature from "./dashboardComponents/feature";

export default function Dashboard() {
  const [currentPage, setCurrentPage] = useState<any>("");
  const getCurrentPage = (page: any) => {
    setCurrentPage(page);
  };
  return (
    <div>
      {/* top headling */}
      <TopHeader />
      {/* top navBar */}
      <div className="px-20 pt-24">
        <TopNavBar getCurrentPage={getCurrentPage} />
      </div>
      {/* top body */}
      <TopBody />
      <div className="bg-[#FAD60E] bg-opacity-10 flex flex-col py-6">
        {/* partner */}
        <TopBodyStrength />
        <PartnerView />
        {/* mid body */}
      </div>

      <MidBody />
      <div className="bg-white py-10">
        <MidBodyFeature />
      </div>
      {/* bottom body  */}
      <BottomBody />
    </div>
  );
}
