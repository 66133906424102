import React from "react";

export default function SearchField({ value, onChangeCallback }: any) {
  const handleChange = (event: any) => {
    const newValue = event.target.value;
    onChangeCallback(newValue);
  };

  return (
    <div className="relative  border-2 border-[#B5CB99]">
      <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
        <svg
          className="w-6 h-6 text-gray-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-4.35-4.35"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 10.5C15 6.91015 12.0899 4 8.5 4C4.91015 4 2 6.91015 2 10.5C2 14.0899 4.91015 17 8.5 17C10.4796 17 12.2816 16.1015 13.3284 14.6716"
          />
        </svg>
      </div>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        className="w-[645px] h-[46px] pl-10 pr-2  border-gray-300 focus:outline-none focus:border-gray-300"
        placeholder="Search"
      />
    </div>
  );
}
