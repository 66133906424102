import React from "react";
import bottomImge from "../../../assets/bull.png";

export default function BottomBody() {
  return (
    <div className="px-60 py-10 pb-40">
      <div className="flex flex-row justify-between ">
        <div className="flex flex-col justify-start w-[532px]  ">
          <div className="pt-40 text-[#186F65] font-bold text-[42px] ">
            Access To Learning Anytime And Anywhere
          </div>
          <div className=" pt-6 text-[#B5CB99] font-semibold  text-base tracking-[2%] break-words">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummyLorem
            Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry's
          </div>
          <button className="w-[140px] mt-12 flex items-center  justify-center h-[32px] gap-[12.16px] text-sm font-bold border bg-[#FFC100] border-[#FFC100] text-white rounded-full hover:bg-[#083156] hover:text-white transition-all duration-500">
            Get Started
          </button>
        </div>

        <div>
          <img className=" " src={bottomImge} alt="bottom" />
        </div>
      </div>
    </div>
  );
}
