import React from "react";
  import Dashboard from "../dashboard/dashboard";

export default function LMS() {
 
  return (
    <div className="bg-[#FFFAED]">
   
      <Dashboard />
    </div>
  );
}
