import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { FaPlay } from "react-icons/fa6";

export default function WebinarHeader() {
  const courseDetails = useAppSelector(
    (state: any) => state?.program.programDetails.item
  );

  return (
    <div className="py-10">
      <div className="relative w-full h-[490px]">
        <img
          className="w-full h-full object-cover  rounded-lg"
          src={courseDetails.url}
          alt={courseDetails.courserName}
        />

        {/* Centered Play Button */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-[60px] h-[60px] bg-[#FF1717] bg-opacity-50 rounded-full   flex items-center justify-center">
            <FaPlay size={30} color="#Ffffffff" />
          </div>
        </div>

        {/* Top-Left Title */}
        <div className="absolute top-0 left-0 m-2 px-2 gap-1 p-1 flex  flex-row  justify-start bg-white rounded-full">
          <div className="text-[10px] font-normal items-center bg-black bg-opacity-5 rounded-full p-1">
            English
          </div>
          <div>
            <div className="w-[20px] h-[20px] bg-[#FF1717]  rounded-full   flex items-center justify-center">
              <FaPlay size={10} color="#Ffffffff" />
            </div>
          </div>
          <div className="text-sm font-normal items-center">
            play introduction
          </div>
        </div>
      </div>
    </div>
  );
}
