import React from "react";
import { IoIosPeople } from "react-icons/io";
import { BiSolidDiscount } from "react-icons/bi";
import { FaGraduationCap } from "react-icons/fa6";
import { RiLiveFill } from "react-icons/ri";
import headerBody from "../../../assets/heder1.png";

import partner from "../../../assets/partner.png";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export default function TopBody() {
  const navigate = useNavigate();

  const renderPartners = () => {
    const partners = [];

    for (let i = 0; i < 5; i++) {
      partners.push(
        <div key={i} className="flex flex-row justify-between">
          <img src={partner} alt={`partner-${i}`} />
        </div>
      );
    }

    return partners;
  };

  const onNagigation = () => {
    navigate("/enroll");
  };

  return (
    <div className="px-20 ">
      <div className="flex flex-row justify-between ">
        <div className="flex flex-col justify-start w-[670px]">
          <div className=" mt-20  w-[280px] font-normal bg-[#B5CB99] rounded-full  text-xl tracking-[2%]">
            <div className=" text-[#186F65] px-3 py-1">
              India's #1 Training Institute
            </div>
          </div>
          <div className="pt-2 text-[#186F65] font-extrabold text-5xl tracking-[2%]">
            <div className="flex flex-row justify-start gap-x-3">
              <div>Learn Trading </div>

              <div className="text-[#FCE09B]"> Backends</div>
            </div>
          </div>
          <div className=" pt-6 text-[#186F65] font-medium text-xs tracking-[2%] break-words">
            Discover easy trading methods, indicators, and live sessions at
            tealvue learn Hub. Elevate your
          </div>
          <div className="text-[#186F65] font-medium text-xs tracking-[2%] break-words">
            trading journey with user-friendly tools and expert insights
          </div>

          <div className="pt-12  flex flex-row items-center justify-start">
            <IoIosPeople color="#000000" size={25} />
            <div className="text-[#186F65]  pl-5 pr-1 font-bold text-xs tracking-[2%] break-words">
              15000+
            </div>
            <div className="text-[#186F65] font-medium text-xs tracking-[2%] break-words">
              Enrolled
            </div>
          </div>
          <div className="flex py-2 flex-row items-center justify-start">
            <BiSolidDiscount color="#000000" size={25} />
            <div className="text-[#186F65]  pl-5 pr-1 font-bold text-xs tracking-[2%] break-words">
              70%
            </div>
            <div className="text-[#186F65] font-medium text-xs tracking-[2%] break-words">
              up to discounts
            </div>
          </div>
          <div className="flex   py-2 flex-row items-center justify-start">
            <FaGraduationCap color="#000000" size={25} />
            <div className="text-[#186F65]  pl-5 pr-1 font-bold text-xs tracking-[2%] break-words">
              certificate
            </div>
            <div className="text-[#186F65] font-medium text-xs tracking-[2%] break-words">
              benefit
            </div>
          </div>
          <div className="flex flex-row  py-2 items-center justify-start">
            <RiLiveFill color="#000000" size={25} />
            <div className="text-[#186F65]  pl-5 pr-1 font-bold text-xs tracking-[2%] break-words">
              Live
            </div>
            <div className="text-[#186F65] font-medium text-xs tracking-[2%] break-words">
              webinar & events
            </div>
          </div>
          <div className="flex flex-row justify-start">
            <button
              onClick={onNagigation}
              className="w-[250px] mt-12  flex items-center justify-center h-[32px] gap-[12.16px] text-sm font-bold border bg-[#186F65] border-[#186F65] text-white rounded hover:bg-[#186F65] "
            >
              Free bootcamp register here
              <FaArrowRight color="#FFffffff" size={18} />
            </button>
            <button
              onClick={onNagigation}
              className="w-[180px] mt-12 ml-6  flex items-center justify-center h-[32px] gap-[12.16px] text-sm font-bold border   border-[#186F65] text-[#186F65] rounded hover:bg-[#186F65] "
            >
              our community
              <FaArrowRight color="#186F65" size={18} />
            </button>
          </div>
        </div>

        <div>
          <div className="flex items-center  justify-center">
            <img className=" " src={headerBody} alt="Header Body" />
          </div>
        </div>
      </div>

      {/* <div className="flex flex-row justify-between">{renderPartners()} </div> */}
      <div className="flex flex-row justify-between"> </div>

      {/* <div className="flex flex-row justify-between pt-18 py-6">
        <div className="w-[377.69px]  bg-white p-2s h-[198.52px] p-[10.3px 16.31px] border-2 border-solid   gap-[19.74px] font-montserrat text-17 font-semibold leading-[27px] tracking-[0.02em] text-left">
          <div className="p-4">
            <div className="text-[#186F65] text-lg font-semibold">
              Quality Education
            </div>

            <div className="text-17 pt-2 font-normal leading-[27px] tracking-[0.02em] text-left">
              Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
              eiusmod tempor ut labore.
            </div>
            <button className="w-[123.73px] h-[30.43px] mt-3 p-[1.72px 6.87px] bg-[#186F65] rounded-full gap-[8.58px] font-montserrat text-17 font-normal leading-[27px] tracking-[0.02em] text-center text-[#FFC100]">
              View Course
            </button>
          </div>
        </div>
        <div className="w-[377.69px]  bg-white p-2s h-[198.52px] p-[10.3px 16.31px] border-2 border-solid   gap-[19.74px] font-montserrat text-17 font-semibold leading-[27px] tracking-[0.02em] text-left">
          <div className="p-4">
            <div className="text-[#186F65] text-lg font-semibold">
              Experienced Teachers
            </div>

            <div className="text-17 pt-2 font-normal leading-[27px] tracking-[0.02em] text-left">
              Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
              eiusmod tempor ut labore.
            </div>
            <button className="w-[123.73px] h-[30.43px] mt-3 p-[1.72px 6.87px] bg-[#186F65] rounded-full gap-[8.58px] font-montserrat text-17 font-normal leading-[27px] tracking-[0.02em] text-center text-[#FFC100]">
              View Course
            </button>
          </div>
        </div>
        <div className="w-[377.69px]  bg-white p-2s h-[198.52px] p-[10.3px 16.31px] border-2 border-solid   gap-[19.74px] font-montserrat text-17 font-semibold leading-[27px] tracking-[0.02em] text-left">
          <div className="p-4">
            <div className="text-[#186F65] text-lg font-semibold">
              Intership With Certificate
            </div>

            <div className="text-17 pt-2 font-normal leading-[27px] tracking-[0.02em] text-left">
              Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
              eiusmod tempor ut labore.
            </div>
            <button className="w-[123.73px] h-[30.43px] mt-3 p-[1.72px 6.87px] bg-[#186F65] rounded-full gap-[8.58px] font-montserrat text-17 font-normal leading-[27px] tracking-[0.02em] text-center text-[#FFC100]">
              View Course
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
}
