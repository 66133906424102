import React from "react";
import EnrollTopHeader from "../../enroll/enrollComponents/enrollHeader";
import BlogHeader from "./blogHeader";
import BlogDetailsbody from "./blogDetailsbody";

export default function BlogDetailsPage() {
  return (
    <div className="bg-[#FFFAED]">
      <EnrollTopHeader />
      <div className="bg-[#FEEBD3] pt-20">
        <BlogHeader />
      </div>
      <BlogDetailsbody />
    </div>
  );
}
