import React from "react";
import EnrollTopHeader from "../enroll/enrollComponents/enrollHeader";
import EnrollTopBody from "../enroll/enrollComponents/enrollTopBody";
import EnrollCourse from "../enroll/enrollComponents/exploreCourse";
import EnrollBottom from "../enroll/enrollComponents/enrollBottom";

export default function Course() {
  return (
    <div className="bg-[#FFFAED]">
      <EnrollTopHeader />
      <div className="">
        <EnrollCourse />
        <EnrollBottom />
      </div>
    </div>
  );
}
