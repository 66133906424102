import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { FaPlay } from "react-icons/fa6";
import { IoMdPerson } from "react-icons/io";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { GiDuration } from "react-icons/gi";
import { AiFillProject } from "react-icons/ai";
import { PiCertificateBold } from "react-icons/pi";
import { PiCurrencyInrBold } from "react-icons/pi";

export default function DetailHeader() {
  const [hover, setHover] = React.useState<any>(-1);

  const labels: any = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  function getLabelText(value: any) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  const courseDetails = useAppSelector(
    (state: any) => state?.program.programDetails.item
  );

  return (
    <div className="flex flex-row justify-between gap-x-10">
      {/* imgeView */}
      <div className="relative w-[550px] h-[273px]">
        <img
          className="w-full h-full object-cover  rounded-lg"
          src={courseDetails.url}
          alt={courseDetails.courserName}
        />

        {/* Centered Play Button */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-[60px] h-[60px] bg-[#FF1717] bg-opacity-50 rounded-full   flex items-center justify-center">
            <FaPlay size={30} color="#Ffffffff" />
          </div>
        </div>

        {/* Top-Left Title */}
        <div className="absolute top-0 left-0 m-2 px-2 gap-1 p-1 flex  flex-row  justify-start bg-white rounded-full">
          <div className="text-[10px] font-normal items-center bg-black bg-opacity-5 rounded-full p-1">
            English
          </div>
          <div>
            <div className="w-[20px] h-[20px] bg-[#FF1717]  rounded-full   flex items-center justify-center">
              <FaPlay size={10} color="#Ffffffff" />
            </div>
          </div>
          <div className="text-sm font-normal items-center">
            play introduction
          </div>
        </div>
      </div>

      {/* programDEtailView? */}
      <div>
        <div className="flex flex-row justify-between gap-x-2   ">
          <div className="">
            <div className="text-[27px] font-bold ">
              {courseDetails.courserName}
            </div>
            <div className="text-base font-normal    ">{courseDetails.des}</div>
          </div>

          <div className="items-baseline gap-4 gap-y-2 pt-10">
            <button className="w-[190px] h-[40px] rounded-full border border-[#186F65] text-[#186F65] flex items-center justify-center">
              <div>Start Subscriptions</div>
            </button>
            <button className="w-[190px] h-[40px] rounded-full border bg-[#186F65] text-white flex items-center justify-center">
              <div>Bye This Course</div>
            </button>
          </div>
        </div>
        <div className="h-[100px] flex flex-row justify-between bg-white mt-12 rounded-md p-4">
          <div>
            <div className="flex felx-row  justify-start items-center ">
              <IoMdPerson color="#6A6B6C" />
              <div className="font-poppins pl-2 text-base font-normal leading-15 text-left text-[#827A7A]">
                {courseDetails.people} Student
              </div>
            </div>
            <div className="flex items-center pt-2">
              <div className="font-poppins text-lg font-bold leading-15 text-[#827A7A]">
                {courseDetails.rating}
              </div>
              <div className="items-center justify-center pt-2 ">
                <Rating
                  size="small"
                  name="hover-feedback"
                  value={courseDetails.rating}
                  precision={0.5}
                  getLabelText={getLabelText}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex felx-row  justify-start items-center ">
              <GiDuration color="#6A6B6C" />
              <div className="font-poppins pl-2 text-base font-normal leading-15 text-left text-[#186F65]">
                {courseDetails.duration}
              </div>
            </div>
            <div className="flex items-center pt-3">
              <div className="font-poppins text-xs bg-[#B5CB99] bg-opacity-30  rounded-full py-1 px-4 font-medium leading-15 text-[#86F65]">
                {courseDetails.lession} lession
              </div>
            </div>
          </div>
          <div>
            <div className="flex felx-row  justify-start items-center ">
              <AiFillProject color="#6A6B6C" />
              <div className="font-poppins pl-2 text-base font-normal leading-15 text-left text-[#186F65]">
                {courseDetails.projectKit}
              </div>
            </div>
            <div className="flex items-center pt-3">
              <div className="font-poppins text-xs bg-[#B5CB99] bg-opacity-30  rounded-full py-1 px-4 font-medium leading-15 text-[#86F65]">
                Project kit
              </div>
            </div>
          </div>
          <div>
            <div className="flex felx-row  justify-start items-center ">
              <PiCertificateBold color="#6A6B6C" />
              <div className="font-poppins pl-2 text-base font-normal leading-15 text-left text-[#186F65]">
                certificate / internship{" "}
              </div>
            </div>
            <div className="flex items-center pt-3">
              <div className="font-poppins text-xs bg-[#B5CB99] bg-opacity-30  rounded-full py-1 px-4 font-medium leading-15 text-[#86F65]">
                Free
              </div>
            </div>
          </div>
          <div>
            <div
              className="flex felx-row items-center
              justify-center pt-3"
            >
              <PiCurrencyInrBold size={20} color="#186F65" />

              <div className="font-poppins text-xl font-bold leading-15 text-left text-[#186F65]">
                {courseDetails.price}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
