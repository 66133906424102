import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import GoogleAuth from "../pages/auth/GoogleAuth";
import LMS from "../pages/lms/lms";
import EnrollDashboard from "../pages/enroll/enrollDashboard";
import Course from "../pages/course/course";
import Details from "../pages/details";
import CardView from "../pages/cart";
import ProfileDashboard from "../pages/profile";
import { useEffect } from "react";
import { updateCurrentButton } from "../redux/slices/program";
import Login from "../pages/auth/login";
import PasswordForgot from "../pages/auth/passwordForgot";
import OtpVerification from "../pages/auth/otpVerification";
import Register from "../pages/auth/register";
import ForgotPassword from "../pages/auth/forgotPassword";
import useCourse from "../hooks/useCourse";
import BlogDetailsPage from "../pages/details/blogDetails/blogDetailsPage";
import BundleDetailsPage from "../pages/details/bundleDetails/bundleDetails";
import WebinarDetails from "../pages/details/webinarDetails/webinarDetails";
import ProgramView from "../pages/program/program";
import Community from "../pages/community/community";
import News from "../pages/news/news";
import usePurchase from "../hooks/userPurchase";
import ProgramDetailPage from "../pages/program/programDetailPage";

function Router() {
  const dispatch = useAppDispatch();
  const { getAllCourses } = useCourse();

  const { getPurchageById } = usePurchase();
  const userInfo = useAppSelector((state) => state.user);
  const isUser = useAppSelector((state: any) => state.user.isAuth);

  const userId = useAppSelector((state: any) => state?.user.data?.id);

  const coursesData = async () => {
    getAllCourses();

    if (isUser) {
      getPurchageById({ userId });
    }
  };

  useEffect(() => {
    coursesData();
  }, []);

  useEffect(() => {
    dispatch(updateCurrentButton("Home"));
  });
  const beforeAuth = [
    // {
    //   path: "/login",
    //   element: <GoogleAuth />,
    // },
    {
      path: "/*",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/forgotPassword",
      element: <ForgotPassword />,
    },

    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/passwordForgot",
      element: <PasswordForgot />,
    },
    {
      path: "/otpVerification",
      element: <OtpVerification />,
    },
  ];
  const AfterAuth = [
    {
      path: "/*",
      element: <LMS />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/forgotPassword",
      element: <ForgotPassword />,
    },

    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/passwordForgot",
      element: <PasswordForgot />,
    },
    {
      path: "/otpVerification",
      element: <OtpVerification />,
    },

    {
      path: "/home",
      element: <LMS />,
    },
    {
      path: "/enroll",
      element: <EnrollDashboard />,
    },
    {
      path: "/courses",
      element: <Course />,
    },
    {
      path: "/details",
      element: <Details />,
    },

    {
      path: "/programDetail",
      element: <ProgramDetailPage />,
    },

    {
      path: "/program",
      element: <ProgramView />,
    },
    {
      path: "/community",
      element: <Community />,
    },
    {
      path: "/news",
      element: <News />,
    },

    {
      path: "/blog",
      element: <BlogDetailsPage />,
    },
    {
      path: "/bundle",
      element: <BundleDetailsPage />,
    },
    {
      path: "/webinar",
      element: <WebinarDetails />,
    },

    {
      path: "/cart",
      element: <CardView />,
    },

    {
      path: "/profile",
      element: <ProfileDashboard />,
    },
  ];
  // return useRoutes(isUser ? AfterAuth : beforeAuth);
  return useRoutes(AfterAuth);
}

export default Router;
