import React from "react";
import enroll from "../../../assets/photo2.png";

import review from "../../../assets/photo.png";

import review2 from "../../../assets/photo01.png";
import { PiBookmarks, PiGraduationCap } from "react-icons/pi";
import { MdOutlinePersonOutline } from "react-icons/md";

import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

export default function EnrollBottom() {
  const [value, setValue] = React.useState<any>(4.9);
  const [hover, setHover] = React.useState<any>(-1);

  const labels: any = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  function getLabelText(value: any) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }
  return (
    <div className="px-20 ">
      <div className="flex flex-row justify-between ">
        <div className="flex items-start  justify-start">
          <img className="h-[458px] w-[503px]" src={enroll} alt="enroll" />
        </div>
        <div className="flex flex-col justify-start w-[557px] pt-4">
          <div className="pt-2 text-[#083156] font-bold text-[45px] ">
            Benefit from our online Learning Expert
          </div>

          <div className="flex flex-row justify-start gap-4 pt-2w ">
            <PiGraduationCap size={30} />

            <div className="flex flex-col   justify-start   pr-[250px]">
              <div className="text-[#083156] font-semibold text-xl tracking-[2%] break-words">
                Online Degree
              </div>
              <div className="font-medium w-[250px] text-sm tracking-[2%] break-words text-[#6A6B6C]">
                Contrary to popular belief, Lorem popular belief, Lorem Ipsum is
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-start gap-4 py-4">
            <PiBookmarks size={30} />

            <div className="flex flex-col   justify-start   pr-[250px]">
              <div className="text-[#083156] font-semibold text-xl tracking-[2%] break-words">
                Short course
              </div>
              <div className="font-medium w-[250px] text-sm tracking-[2%] break-words text-[#6A6B6C]">
                Contrary to popular belief, Lorem popular belief, Lorem Ipsum is
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-start gap-4">
            <MdOutlinePersonOutline size={30} />

            <div className="flex flex-col   justify-start   pr-[250px]">
              <div className="text-[#083156] font-semibold text-xl tracking-[2%] break-words">
                Learn with expert
              </div>
              <div className="font-medium w-[250px] text-sm tracking-[2%] break-words text-[#6A6B6C]">
                Contrary to popular belief, Lorem popular belief, Lorem Ipsum is
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-center">
        <div className="flex flex-col justify-center pt-16">
          <div className="pt-2 text-center text-[#083156] font-bold text-[45px]">
            Student says About Course
          </div>
          <div className="w-[680px] pt-3 text-center text-[#827A7A] font-medium text-base tracking-[2%] break-words">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between py-16">
        <div className="relative bg-white w-[561px] h-[322px] rounded-2xl flex flex-col justify-start ">
          <div className="flex flex-row justify-start  p-4">
            <img
              className="h-[257px] w-[202px] m-4"
              src={review}
              alt="review "
            />
            <div className="flex flex-col justify-start pt-4">
              <Box
                sx={{
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Rating
                  name="hover-feedback"
                  value={value}
                  precision={0.5}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                {value !== null && (
                  <Box sx={{ ml: 2 }}>
                    {labels[hover !== -1 ? hover : value]}
                  </Box>
                )}
              </Box>

              <div className="font-poppins py-8 text-2xl font-semibold leading-15 text-left">
                Best learning platform
              </div>
              <div className="font-medium w-[263px] text-sm tracking-[2%] break-words text-[#6A6B6C]">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45
              </div>
              <div className="font-poppins   text-xs font-semibold leading-15 text-left pt-4">
                Maurice Cain
              </div>

              <div className="font-poppins    text-[10px] font-semibold leading-15 text-left text-[#827A7A]">
                Student
              </div>
            </div>
          </div>
        </div>
        <div className="relative bg-white w-[561px] h-[322px] rounded-2xl flex flex-col justify-start ">
          <div className="flex flex-row justify-start   p-4">
            <img
              className="h-[257px] w-[202px] m-4"
              src={review2}
              alt="review2 "
            />
            <div className="flex flex-col justify-start pt-4">
              <Box
                sx={{
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Rating
                  name="hover-feedback"
                  value={value}
                  precision={0.5}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                {value !== null && (
                  <Box sx={{ ml: 2 }}>
                    {labels[hover !== -1 ? hover : value]}
                  </Box>
                )}
              </Box>

              <div className="font-poppins py-8 text-2xl font-semibold leading-15 text-left">
                Amazing Course{" "}
              </div>
              <div className="font-medium w-[263px] text-sm tracking-[2%] break-words text-[#6A6B6C]">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45
              </div>
              <div className="font-poppins   text-xs font-semibold leading-15 text-left pt-4">
                Lila Henderson
              </div>

              <div className="font-poppins    text-[10px] font-semibold leading-15 text-left text-[#827A7A]">
                Student
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
