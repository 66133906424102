import React, { useEffect, useState } from "react";
import useCourse from "../../../hooks/useCourse";
import { useNavigate } from "react-router-dom";
import { Box, Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { updateItem } from "../../../redux/slices/program";

import { useDispatch } from "react-redux";
import { updateIscurrentCourseContentPaid } from "../../../redux/slices/course";
import useCourseContent from "../../../hooks/useCourseContent";

export default function BundleCombo({ courseId }: any) {
  const navigate = useNavigate();
  const { getCourseById } = useCourse();
  const { getCourseContentById } = useCourseContent();

  const dispatch = useDispatch();

  const [value, setValue] = React.useState<any>(4.9);
  const [hover, setHover] = React.useState<any>(-1);

  const [course, setCourse] = useState<any>({});

  const getCourserDetails = async () => {
    const detail = await getCourseById({ courseId });
    if (detail.course) {
      setCourse(detail.course);
    }
  };
  const courseContentData = async (contentId: any) => {
    await getCourseContentById(contentId);
  };

  useEffect(() => {
    getCourserDetails();
  }, []);
  const labels: any = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  function getLabelText(value: any) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  return (
    <div className="m-1">
      <div>
        <div className="relative bg-white rounded-xl flex flex-col justify-start">
          <button
            onClick={() => {
              dispatch(updateItem(course));
              dispatch(updateIscurrentCourseContentPaid(false));
              courseContentData({ courseId: course.courseId });

              navigate("/details");
            }}
            className="flex flex-row justify-center"
          >
            <img
              className="h-[170px] w-[320px] p-1"
              src={course.url}
              alt="enroll "
            />
          </button>

          {course.type == "coursesLibrary" && (
            <div className="flex flex-row justify-between gap-2">
              <div className="font-poppins py-1 pl-4 text-xs font-semibold leading-15 text-left text-[#827A7A]">
                {course.lession} lessons
              </div>
              <div className="font-poppins py-1 pr-2 pl-4 text-xs font-semibold leading-15 text-left text-[#827A7A]">
                Course ID : {course.courseId.substring(0, 10)}
              </div>
            </div>
          )}

          {course.type == "coursesLibrary" && (
            <div className="font-poppins py-1 pl-4 text-xl font-semibold leading-15 text-left">
              {course.courserName}
            </div>
          )}

          {course.type == "coursesLibrary" && (
            <div className="flex flex-row justify-between pl-4">
              <div className="flex flex-row justify-start">
                <div className="font-poppins py-1 text-sm font-semibold leading-15 text-left">
                  {course.price}
                </div>
                <div className="font-poppins py-1 pl-1 mt-1 items-baseline text-xs font-semibold leading-15 text-left text-[#827A7A] line-through">
                  {course.discount}
                </div>
              </div>
              <Box
                sx={{
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Rating
                  name="hover-feedback"
                  value={course.rating}
                  precision={0.5}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                {course.rating !== null && (
                  <Box sx={{ ml: 2 }}>
                    {labels[hover !== -1 ? hover : course.rating]}
                  </Box>
                )}
              </Box>

              <div className="font-poppins py-1 pl-1 pr-1 items-baseline text-xs font-semibold leading-15 text-left text-[#827A7A]">
                {course.rating} Rating
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
