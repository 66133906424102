import React from "react";
import EnrollTopHeader from "../../enroll/enrollComponents/enrollHeader";
import BundleHeader from "./bundleHeader";
import BundleCourse from "./bundleCourse";

export default function BundleDetailsPage() {
  return (
    <div className="bg-[#FFFAED]">
      <EnrollTopHeader />

      <div className="bg-[#FEEBD3] pt-20">
        <BundleHeader />
      </div>

      <div className="flex flex-row justify-between px-20">
        <BundleCourse />
      </div>
    </div>
  );
}
