import React from "react";
import EnrollTopHeader from "../enroll/enrollComponents/enrollHeader";
import CartComponents from "./cartComponents";
import CartTotal from "./cartTotal";

export default function CardView() {
  return (
    <div className="bg-[#FFFAED]">
      <EnrollTopHeader />

      <div className="pt-10">
        <CartComponents />
      </div>
    </div>
  );
}
