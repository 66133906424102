import { Provider } from "react-redux";
import Router from "./routes/index";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useSearchParams } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import makeStore from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import "./App.css";
import React from "react";
import axios, { AxiosError } from "axios";

function AxiosInterceptor({ children }: { children: any }) {
  // const location = useLocation();
  // const searchParams = useSearchParams();

  const errorComposer = (error: AxiosError) => {
    return () => {
      toast.dismiss();
      const statusCode: number | null = error.response
        ? error.response.status
        : null;

      if (statusCode === 400 || statusCode === 404) {
        toast.error(
          error?.message ?? "Something went wrong ! Please try again later 😪"
        );
      } else if (statusCode === 401 || statusCode === 403) {
        // ? Redirect to login page
        // check if the route  do somethings

        // ? Clear the local storage
        localStorage.clear();
      } else {
        toast.error("Something went wrong ! Please try again later");
      }
    };
  };

  axios.interceptors.response.use(undefined, function (error) {
    error.handleGlobally = errorComposer(error);
    return Promise.reject(error);
  });

  return children;
}

 

function App() {
  return (
    <AxiosInterceptor>
      <Provider store={makeStore.reduxStore}>
        <PersistGate loading={null} persistor={makeStore.persistor}>
          <BrowserRouter>
            <Router />
            <ToastContainer
              position={"top-right"}
              autoClose={3000}
              hideProgressBar={true}
              closeOnClick
              draggable={false}
              enableMultiContainer
              bodyClassName="lg:min-h-[70px] min-h-[50px] px-4 flex gap-4"
            />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </AxiosInterceptor>
  );
}

export default App;
