import React, { useEffect, useState } from "react";
import useProgress from "../../../hooks/useProgress";
import { useAppSelector } from "../../../redux/hooks";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { addPaidCourserProgrss } from "../../../redux/slices/course";
export default function GetProgress({ courseId }: any) {
  const dispatch = useDispatch();
  const { getProgressByCourse } = useProgress();
  const userId = useAppSelector((state: any) => state?.user.data?.id);

  const [progressValue, setprogressValue] = useState<any>(0);
  const getProgress = async () => {
    const data = await getProgressByCourse({
      userId: userId,
      courseId: courseId,
    });

    if (data.progress) {
      dispatch(
        addPaidCourserProgrss({
          courseId: data.progress[0].courseId,
          courseProgress: data.progress,
        })
      );

      const sum = data.progress.reduce(
        (total: any, item: any) => total + item.progressValue,
        0
      );
      setprogressValue(sum);
    }
  };
  useEffect(() => {
    getProgress();
  }, []);

  const PrettoSlider = styled(Slider)({
    color: "#52af77",
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&::before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "#52af77",
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&::before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });
  return (
    <div className="px-2">
      {/* <div>{progressValue}%</div> */}
      <div className="font-poppins text-sm font-semibold leading-15 text-left">
        Progress:{progressValue}%
      </div>
      <PrettoSlider
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        defaultValue={progressValue}
      />
    </div>
  );
}
