import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import DetailHeader from "./detailHeader";
import DetailAgenda from "./detailAgenda";

export default function DetailPage() {
  const courseDetails = useAppSelector(
    (state: any) => state?.program.programDetails.item
  );

  return (
    <div>
      <div className="px-20 py-10">
        <DetailHeader />
      </div>
    </div>
  );
}
