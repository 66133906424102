import React from "react";
import program from "../../assets/program.png";
import { CiMicrophoneOn } from "react-icons/ci";
import { GiDuration } from "react-icons/gi";
import { MdOutlineVisibility } from "react-icons/md";
import type { Dayjs } from "dayjs";
import { Calendar, theme } from "antd";
import type { CalendarProps } from "antd";
import { useNavigate } from "react-router-dom";

const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>["mode"]) => {
  console.log(value.format("YYYY-MM-DD"), mode);
};

export default function ProgramList() {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="flex flex-row justify-start gap-x-20  py-16">
        <div>
          <div className="font-medium text-sm text-[#FFC800]">STARTUP LOFT</div>
          <div className="font-bold text-[41px] text-[#186F65]">Program</div>
        </div>

        <div className="font-normal text-xs text-[#000000] w-[800px] items-center">
          Vivamus gravida molestie ante a placerat. Nam semper in quam eget
          iaculis. Nullam ornare vulputate tellus et pharetra. Mauris sem magna,
          feugiat eget quam sed, hendrerit scelerisque mi. In bibendum sit amet
          nulla
        </div>
      </div>

      <div className="px-3 flex flex-row justify-start">
        <div className="">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
            return (
              <div className="flex flex-row justify-start py-4 ">
                <div className="px-6">
                  <img src={program} alt="logo" />
                </div>
                <div className=" w-[671px]">
                  <div className="h-[43px] bg-[#FEEBD3] flex flex-row  ">
                    <div className="flex justify-center items-center h-[43px] w-[136px] bg-[#F1C40F] bg-opacity-20 text-[17px] font-normal">
                      Meetup
                    </div>
                    <div className="flex justify-center items-center h-[43px] w-[136px]   text-[17px] font-normal">
                      <div className="flex flex-row gap-x-2   justify-center items-center">
                        <div>
                          <CiMicrophoneOn />
                        </div>
                        <div>aravind</div>
                      </div>
                    </div>
                    <div className="flex justify-center items-center h-[43px] w-[136px]   text-[17px] font-normal">
                      <div className="flex flex-row gap-x-2   justify-center items-center">
                        <div>
                          <GiDuration />
                        </div>
                        <div> 11:40</div>
                      </div>
                    </div>{" "}
                    <div className="flex justify-center items-center h-[43px] w-[136px]   text-[17px] font-normal">
                      <div className="flex flex-row gap-x-2   justify-center items-center">
                        <div>
                          <MdOutlineVisibility />
                        </div>
                        <div> 11.3k</div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col justify-start p-4">
                    <div className="flex   text-1 font-semibold text-base">
                      Get your trading superpowers
                    </div>
                    <div className="text-[13px] font-normal py-2">
                      Vivamus gravida molestie ante a placerat. Nam semper in
                      quam eget iaculis. Nullam ornare vulputate tellus et
                      pharetra. Mauris sem magna, feugiat eget quam sed,
                      hendrerit scelerisque mi. In bibendum sit amet nulla
                    </div>
                    <div className="text-[13px] font-normal underline pt-2">
                      <button
                        onClick={() => {
                          navigate("/programDetail");
                        }}
                        className=" underline pt-2"
                      >
                        View Program details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="ml-4  w-[300px] rounded-none">
          <Calendar fullscreen={false} onPanelChange={onPanelChange} />
        </div>
      </div>
    </div>
  );
}
