import React, { useState } from "react";

export default function DetailAgenda() {
  const [currentIndex, setCurrentIndex] = useState<any>(0);
  return (
    <div className="pt-10 bg-[#CCCCCC] bg-opacity-20 p-4    ">
      <div className="flex items-center justify-center  p-4 bg-white rounded-full">
        <div className="flex flex-row items-baseline gap-4 gap-y-2 gap-x-4">
          <button
            onClick={() => {
              setCurrentIndex(0);
            }}
            className={`w-[180px] h-[40px] rounded-full border ${
              currentIndex === 0
                ? "border-[#186F65] text-[#186F65]"
                : "bg-[#186F65] text-white"
            } flex items-center justify-center`}
          >
            <div>What you’ll learn</div>
          </button>

          <button
            onClick={() => {
              setCurrentIndex(1);
            }}
            className={`w-[180px] h-[40px] rounded-full border ${
              currentIndex === 1
                ? "border-[#186F65] text-[#186F65]"
                : "bg-[#186F65] text-white"
            } flex items-center justify-center`}
          >
            <div>Requirements</div>
          </button>
          <button
            onClick={() => {
              setCurrentIndex(2);
            }}
            className={`w-[180px] h-[40px] rounded-full border ${
              currentIndex === 2
                ? "border-[#186F65] text-[#186F65]"
                : "bg-[#186F65] text-white"
            } flex items-center justify-center`}
          >
            <div>Comments</div>
          </button>
        </div>
      </div>

      {currentIndex == 0 && (
        <div>
          <ol className="list-none gap-y-10">
            <li className="flex items-center py-4">
              <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2 "></div>
              <span className="text-[#263238]">
                Completely understand how the stock market works.
              </span>
            </li>
            <li className="flex items-center py-4">
              <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2 "></div>
              <span className="text-[#263238]">
                Know the process that a company goes through to become a
                Publicly Traded Company
              </span>
            </li>
            <li className="flex items-center py-4">
              <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2 "></div>
              <span className="text-[#263238]">
                Know the history of the Stock Market and it's Evolution.
              </span>
            </li>
            <li className="flex items-center py-4">
              <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2 "></div>
              <span className="text-[#263238]">
                Master Technical Analysis: Candlestick Patterns, Chart Patters,
                Volume and Technical Indicators.
              </span>
            </li>
            <li className="flex items-center py-4">
              <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2 "></div>
              <span className="text-[#263238]">
                Filter out the noise, get to know what our traders use and how
                they think. Get insight from a professional trader's standpoint.
              </span>
            </li>
            <li className="flex items-center py-4">
              <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2 "></div>
              <span className="text-[#263238]">
                How to send Orders and different Order Types.
              </span>
            </li>
            <li className="flex items-center py-4">
              <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2 "></div>
              <span className="text-[#263238]">
                Different Exchanges: NYSE, NASDAQ & AMEX.
              </span>
            </li>
            <li className="flex items-center py-4">
              <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2 "></div>
              <span className="text-[#263238]">
                Risk Management: how to reduce losses and manage overall
                portfolio risk.
              </span>
            </li>
            <li className="flex items-center py-4">
              <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2 "></div>
              <span className="text-[#263238]">
                Money Management: how to manage risk on each position, how many
                shares to buy, and where to take a loss.
              </span>
            </li>
            <li className="flex items-center py-4">
              <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2 "></div>
              <span className="text-[#263238]">
                How to short sell so you can make money if the market goes down
              </span>
            </li>
            <li className="flex items-center py-4">
              <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2 "></div>
              <span className="text-[#263238]">
                Trading Psychology: become an Expert in human Emotions and the
                different Biases that affect people when Money is involved.
              </span>
            </li>
          </ol>
        </div>
      )}
      {currentIndex == 1 && (
        <div>
          <ol className="list-none gap-y-10">
            <li className="flex items-center py-4">
              <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2 "></div>
              <span className="text-[#263238]">
                An Open Mind & Willingness to Learn{" "}
              </span>
            </li>
          </ol>
        </div>
      )}
      {currentIndex == 2 && (
        <div>
          <div className="list-none gap-y-10">
            <li className="flex items-center py-4">
              <span className="text-[#263238]">No Comments</span>
            </li>
          </div>
        </div>
      )}
    </div>
  );
}
