import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { LuMail } from "react-icons/lu";
import { IoMdTime } from "react-icons/io";
import { Col, Row } from "antd";

export default function Certificates() {
  return (
    <div className="flex justify-center py-20">
      <div className="w-1/2 rounded-lg border-2 justify-center items-center">
        <Row>
          <Col span={18}>
            <div className="font-semibold text-sm p-1">Course Name</div>
          </Col>
          <Col span={6}>
            <div className="font-semibold text-sm p-1">Action</div>
          </Col>
        </Row>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
          return (
            <Row className="my-1 border-t-2 ">
              <Col span={18}>
                <div className="font-normal text-sm p-1">
                  trading full course
                </div>
              </Col>
              <Col span={6}>
                <div className="font-normal text-sm p-1">Download</div>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
}
