import { combineReducers } from "redux";
import charts from "./slices/charts";
import UserInfoToken from "./slices/UserInfoToken";
import user from "./slices/user";
import program from "./slices/program";
import course from "./slices/course";

export const rootReducer = combineReducers({
  charts,
  UserInfoToken,
  user,
  program,
  course,
});
