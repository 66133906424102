import axios from "axios";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  IUserData,
  updateLogin,
  updateLogout,
  updateUserData,
} from "../redux/slices/user";
import { toast } from "react-toastify";

function useProfile() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userAccessToken = useAppSelector((state) => state.user.token);

  //? Create Profile for new User

  const createNewUserProfile = async (userData: any) => {
    try {
      console.log("userData", userData);
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/api/v1/user/register`,
        userData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      if (data) {
        toast.success("Successfully Created");
        navigate("/login");
      }
      return data;
    } catch (error) {
      toast.error("Failed");
      return error;
    }
  };

  //? login user
  const loginUser = async (userData: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/api/v1/user/login`,
        userData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      if (data) {
        dispatch(
          updateLogin({
            isAuth: true,
            token: data.accessToken,
            isEditMode: false,
            data: data.user,
          })
        );
        toast.success("Successfully login");
        navigate("/home");
      }
      return data;
    } catch (error) {
      toast.error("Failed login");
      return error;
    }
  };

  const forgotPassword = async (userData: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/api/v1/user/password/reset`,
        userData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      if (data) {
        toast.success("Link send to Email");
        navigate("/login");
      }
      return data;
    } catch (error) {
      toast.error("Failed login");
      return error;
    }
  };

  // ? Reset the data while logout
  const handleUserLogout = useCallback(() => {
    dispatch(updateLogout());
    dispatch(updateUserData({} as IUserData));

    setTimeout(() => {
      navigate(`/login`);
    }, 500);
  }, [dispatch, navigate]);

  // ? Fetch user information from api
  const getUserData = useCallback(async () => {
    try {
      if (!userAccessToken) return;

      const { data } = await axios.get(
        `${process.env.REACT_APP_DEVELOPMENT_URL}/api/v1/user/view`,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
          },
        }
      );
      // ? Update user data to the state
      dispatch(updateUserData(data.user as IUserData));
      return data.user as IUserData;
    } catch (error) {}
  }, [dispatch, userAccessToken]);

  const createnewUserForRole = async (userData: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/createUserManagement`,
        userData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      if (data) {
        alert("Sucessfully Created");
      }
      return data;
    } catch (error) {
      alert("Faild");

      return error;
    }
  };

  const getUserRole = async (userData: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/getUserRole`,
        userData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      return data;
    } catch (error) {
      return error;
    }
  };
  const getUserRolebyusernameAndpassword = async (userData: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/getUserRolebyusernameAndpassword`,
        userData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      return data;
    } catch (error) {
      return error;
    }
  };

  const deleteUser = async (userData: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/deleteUser`,
        userData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      return data;
    } catch (error) {
      return error;
    }
  };

  const userUpdate = async (userData: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/userUpdate`,
        userData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      return data;
    } catch (error) {
      return error;
    }
  };

  const userUpdateToken = async (userData: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/userauth/updationOfToken`,
        userData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      return data;
    } catch (error) {
      return error;
    }
  };
  const getheaderTokenVerification = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/userauth/headerTokenVerification`,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      return data;
    } catch (error) {
      return error;
    }
  };

  return {
    handleUserLogout,
    getUserData,
    createnewUserForRole,
    getUserRole,
    deleteUser,
    userUpdate,
    getUserRolebyusernameAndpassword,
    userUpdateToken,
    getheaderTokenVerification,
    createNewUserProfile,
    loginUser,
    forgotPassword,
  };
}

export default useProfile;

// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { useAppDispatch, useAppSelector } from "../redux/hooks";

// function useProfile() {
//   const dispatch = useAppDispatch();
//   const navigate = useNavigate();
//   const userAccessToken = useAppSelector((state) => state.user.token);

// const createnewUserForRole = async (userData: any) => {
//   try {
//     const { data } = await axios.post(
//       `${process.env.REACT_APP_hostName}/api/v1/user/register`,
//       userData,
//       {
//         headers: {
//           "Access-Control-Allow-Origin": "*", // Set the allowed origin
//           "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
//           "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
//         },
//       }
//     );
//     if (data) {
//       alert("Successfully Created");
//     }
//     return data;
//   } catch (error) {
//     alert("Failed");
//     return error;
//   }
// };

//   return {
//     createnewUserForRole,
//   };
// }

// export default useProfile;
