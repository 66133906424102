import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ChartsData {
  type: string;
  subType: string;
  isSelect: boolean;
}

export interface chartState {
  chartsDetails: ChartsData;
  dataList: any;
}

export const initialGlobalState: chartState = {
  chartsDetails: {
    type: " ",
    subType: "string",
    isSelect: true,
  },
  dataList: [],
};

export const charts = createSlice({
  name: "charts",
  initialState: initialGlobalState,
  reducers: {
    chartTypeData: (state, action: PayloadAction<ChartsData>) => {
      state.chartsDetails = action.payload;
    },
    updateDataList: (state, { payload }: PayloadAction<any>) => {
      state.dataList.push(payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { chartTypeData, updateDataList } = charts.actions;

export default charts.reducer;
