import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { IconButton } from "@mui/material";
import { FaPlay } from "react-icons/fa6";
import { IoMdPerson } from "react-icons/io";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
// import enroll from "../../../assets/photo2.png";
import StarIcon from "@mui/icons-material/Star";
import { GiDuration } from "react-icons/gi";
import { AiFillProject } from "react-icons/ai";
import { PiCertificateBold } from "react-icons/pi";
import { PiCurrencyInrBold } from "react-icons/pi";

export default function BundleHeader() {
  const [hover, setHover] = React.useState<any>(-1);

  const labels: any = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  function getLabelText(value: any) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  const courseDetails = useAppSelector(
    (state: any) => state?.program.programDetails.item
  );

  return (
    <div className="px-20 py-10">
      <div className=" flex flexx-row justify-between">
        <div className="">
          <div
            className="text-[27px] font-medium text-ellipsis
           text-[#186F65] "
          >
            Trading course
            {/* {courseDetails.courserName} */}
          </div>
          <div className="text-base font-normal text-[#7D8B98]   w-[600px] ">
            {/* {courseDetails.des} */}
            Lorem ipsum dolor sit amet consectetur adipiscingLorem ipsum dolor
            sit amet consectetur adipiscingLorem ipsum dolor sit amet
            consectetur adipiscingLorem ipsum dolor sit amet consectetur
            adipiscing
          </div>
        </div>
        <img
          className="  object-cover  rounded-lg  w-[400px] h-[100px]"
          src={courseDetails.url}
          alt={courseDetails.courserName}
        />
      </div>

      <div className="flex flex-row justify-between">
        <div className="h-[100px] w-full mr-20 flex flex-row justify-between bg-white mt-12 rounded-md p-4">
          <div>
            <div className="flex felx-row  justify-start items-center ">
              <IoMdPerson color="#6A6B6C" />
              <div className="font-poppins pl-2 text-base font-normal leading-15 text-left text-[#827A7A]">
                {courseDetails.people} Student
              </div>
            </div>
            <div className="flex items-center pt-2">
              <div className="font-poppins text-lg font-bold leading-15 text-[#827A7A]">
                {courseDetails.rating}
              </div>
              <div className="items-center justify-center pt-2 ">
                <Rating
                  size="small"
                  name="hover-feedback"
                  value={courseDetails.rating}
                  precision={0.5}
                  getLabelText={getLabelText}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex felx-row  justify-start items-center ">
              <GiDuration color="#6A6B6C" />
              <div className="font-poppins pl-2 text-base font-normal leading-15 text-left text-[#186F65]">
                {/* {courseDetails.duration} */}
                Total Course
              </div>
            </div>
            <div className="flex items-center pt-3">
              <div className="font-poppins text-xs bg-[#B5CB99] bg-opacity-30  rounded-full py-1 px-4 font-medium leading-15 text-[#86F65]">
                22 Course
                {/* {courseDetails.lession} lession */}
              </div>
            </div>
          </div>
          <div>
            <div className="flex felx-row  justify-start items-center ">
              <GiDuration color="#6A6B6C" />
              <div className="font-poppins pl-2 text-base font-normal leading-15 text-left text-[#186F65]">
                {/* {courseDetails.duration} */}
                Duration
              </div>
            </div>
            <div className="flex items-center pt-3">
              <div className="font-poppins text-xs bg-[#B5CB99] bg-opacity-30  rounded-full py-1 px-4 font-medium leading-15 text-[#86F65]">
                250 hours
                {/* {courseDetails.lession} lession */}
              </div>
            </div>
          </div>
          <div>
            <div className="flex felx-row  justify-start items-center ">
              <AiFillProject color="#6A6B6C" />
              <div className="font-poppins pl-2 text-base font-normal leading-15 text-left text-[#186F65]">
                {/* {courseDetails.projectKit} */}
                Requirement
              </div>
            </div>
            <div className="flex items-center pt-3">
              <div className="font-poppins text-xs bg-[#B5CB99] bg-opacity-30  rounded-full py-1 px-4 font-medium leading-15 text-[#86F65]">
                Internet
              </div>
            </div>
          </div>
          <div>
            <div className="flex felx-row  justify-start items-center ">
              <AiFillProject color="#6A6B6C" />
              <div className="font-poppins pl-2 text-base font-normal leading-15 text-left text-[#186F65]">
                {/* {courseDetails.projectKit} */}
                Total Project
              </div>
            </div>
            <div className="flex items-center pt-3">
              <div className="font-poppins text-xs bg-[#B5CB99] bg-opacity-30  rounded-full py-1 px-4 font-medium leading-15 text-[#86F65]">
                Project Kit
              </div>
            </div>
          </div>
          <div>
            <div className="flex felx-row  justify-start items-center ">
              <PiCertificateBold color="#6A6B6C" />
              <div className="font-poppins pl-2 text-base font-normal leading-15 text-left text-[#186F65]">
                certificate / internship{" "}
              </div>
            </div>
            <div className="flex items-center pt-3">
              <div className="font-poppins text-xs bg-[#B5CB99] bg-opacity-30  rounded-full py-1 px-4 font-medium leading-15 text-[#86F65]">
                Free
              </div>
            </div>
          </div>
          {/* <div>
            <div
              className="flex felx-row items-center
              justify-center pt-3"
            >
              <PiCurrencyInrBold size={20} color="#186F65" />

              <div className="font-poppins text-xl font-bold leading-15 text-left text-[#186F65]">
                {courseDetails.price}
              </div>
            </div>
          </div> */}
        </div>

        <div className="flex flex-row justify-between gap-x-2   ">
          <div className="items-baseline gap-4 gap-y-2 pt-10">
            <div className="w-[190px] h-[40px] rounded-full border border-[#186F65] text-[#186F65] flex items-center justify-center">
              <div>Start Subscriptions</div>
            </div>
            <div className="w-[190px] h-[40px] rounded-full border bg-[#186F65] text-white flex items-center justify-center">
              <div>Bye This Course</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
