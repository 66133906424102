import React, { useState } from "react";
import { toast } from "react-toastify";
import YouTube from "react-youtube";

export default function YoutubeVideo({ islock }: any) {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const [isWatch, setWatch] = useState(false);

  const onPlayerReady = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const youtubeContent = () => {
    return (
      <YouTube videoId="5Qi5MDm5pQE" opts={opts} onReady={onPlayerReady} />
    );
  };

  return (
    <div>
      {isWatch ? (
        youtubeContent()
      ) : (
        <button
          className="bg-[#186F65] text-white font-bold py-2 px-4 rounded"
          onClick={() => {
            if (islock) {
              setWatch(true);
            } else {
              toast.warning("First Unlock The Section");
            }
          }}
        >
          Watch Video
        </button>
      )}
    </div>
  );
}
