import React from "react";
import ProfileDetails from "./profileComponents/profile";
import ProfileViewAndUpdation from "./profileComponents/profileNav";
import EnrollTopHeader from "../enroll/enrollComponents/enrollHeader";

export default function ProfileDashboard() {
  return (
    <div className="bg-[#FFFAED]">
      <EnrollTopHeader />
      <div className="px-20 h-full  justify-center pt-28">
        <ProfileDetails />
        <ProfileViewAndUpdation />
      </div>
    </div>
  );
}
