import React, { useState } from "react";
import TopNavBar from "../dashboard/dashboardComponents/topNavBar";
import logoh from "../../assets/logoh.png";
import ProgramList from "./programList";

export default function ProgramView() {
  const [currentPage, setCurrentPage] = useState<any>("");
  const getCurrentPage = (page: any) => {
    setCurrentPage(page);
  };
  return (
    <div>
      <div className="px-20 pt-5 justify-between bg-[#FFFAED]">
        <div className=" bg-white flex flex-row min-w-full p-2">
          <div > 
            <img src={logoh} />
          </div>
          <div className="w-full pl-4">
            <TopNavBar getCurrentPage={getCurrentPage} />
          </div>
        </div>
        <div>
          <ProgramList />
        </div>
      </div>
    </div>
  );
}
