import React, { useEffect } from "react";
// import jsonData from "../../../lms.json";
import { useAppSelector } from "../../../redux/hooks";
import BundleCombo from "./bundlecourseCombo";

export default function BundleCourse() {
  const courseContent = useAppSelector(
    (state) => state.course.currentCourseContent
  );
  useEffect(() => {
    console.log("courseContent bundle", courseContent);
  }, []);
  return (
    <div className="flex flex-wrap">
      {courseContent.description.map((item: any) => {
        return (
          <div key={item}>
            <BundleCombo courseId={item} />
          </div>
        );
      })}
    </div>
  );
}
