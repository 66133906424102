import React, { useState } from "react";
import EditAccout from "./editAccount";
import Certificates from "./certificate";
import Purchase from "./puchased";

export default function ProfileViewAndUpdation() {
  const [activeButton, setActiveButton] = useState("allcourse");

  const handleButtonClick = (buttonName: any) => {
    setActiveButton(buttonName);
  };
  return (
    <div>
      <div>
        <div className="text-sm font-medium text-center text-[#596570] border-b border-[#596570] dark:text-[#596570] dark:border-[#C8C8C8]">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <a
                href="#"
                className={`${
                  activeButton === "allcourse"
                    ? "font-normal text-sm  inline-block p-4 text-[#186F65] border-b-4 border-[#186F65] rounded-t-lg active dark:text-[#186F65] dark:border-[#186F65]"
                    : "font-normal text-sm  inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-[gray-300] dark:hover:text-[gray-300]"
                }`}
                onClick={() => handleButtonClick("allcourse")}
              >
                All Course
              </a>
            </li>
            <li className="mr-2">
              <a
                href="#"
                className={`${
                  activeButton === "wishlist"
                    ? "font-normal text-sm inline-block p-4 text-[#186F65] border-b-4 border-[#186F65] rounded-t-lg active dark:text-[#186F65] dark:border-[#186F65]"
                    : " font-normal text-sm  inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-[gray-300] dark:hover:text-[gray-300]"
                }`}
                onClick={() => handleButtonClick("wishlist")}
              >
                Wishlist
              </a>
            </li>
            <li className="mr-2">
              <a
                href="#"
                className={` ${
                  activeButton === "certificates"
                    ? " font-normal text-sm  inline-block p-4 text-[#186F65] border-b-4 border-[#186F65] rounded-t-lg active dark:text-[#186F65] dark:border-[#186F65]"
                    : "font-normal text-sm  inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-[gray-300] dark:hover:text-[gray-300]"
                }`}
                onClick={() => handleButtonClick("certificates")}
              >
                Certificates
              </a>
            </li>
            <li className="mr-2">
              <a
                href="#"
                className={` ${
                  activeButton === "projectsFiles"
                    ? " font-normal text-sm  inline-block p-4 text-[#186F65] border-b-4 border-[#186F65] rounded-t-lg active dark:text-[#186F65] dark:border-[#186F65]"
                    : "font-normal text-sm  inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-[gray-300] dark:hover:text-[gray-300]"
                }`}
                onClick={() => handleButtonClick("projectsFiles")}
              >
                Projects Files
              </a>
            </li>
          </ul>
        </div>
      </div>
      {activeButton === "allcourse" && <Purchase />}
      {activeButton === "wishlist" && <EditAccout />}
      {activeButton === "certificates" && <Certificates />}
      {activeButton === "projectsFiles" && <Certificates />}
    </div>
  );
}
