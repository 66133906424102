import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import PaidCourse from "./paidCourse";

export default function Purchase() {
  const purchaseCourse = useAppSelector(
    (state: any) => state?.course.purchaseCourse
  );

  const allPaidCourse = purchaseCourse.reduce((prev: any, item: any) => {
    return [...prev, ...item.courseListIds];
  }, []);

  return (
    <div className="flex flex-wrap">
      {allPaidCourse.map((item: any) => {
        return (
          <div key={item}>
            <PaidCourse courseId={item} />
          </div>
        );
      })}
    </div>
  );
}
