import React, { useEffect } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { useDispatch } from "react-redux";
import { updateLogout } from "../../../redux/slices/user";
import { useNavigate } from "react-router-dom";

export default function ProfileDetails() {
  const userInfo = useAppSelector((state: any) => state.user.data);
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log("userInfo", userInfo);
  // }, []);

  const dispatch = useDispatch();
  return (
    <div className="flex flex-row justify-between  py-6">
      <div className="flex flex-row justify-start gap-10 ">
        <img
          src={userInfo?.profile_img_url}
          className="w-[98px] h-[98px] rounded-full bg-green-700 "
        ></img>
        <div className="  items-center pt-4">
          <div className="text-2xl font-medium  ">{userInfo?.name}</div>
          <div className="text-sm font-normal  ">{userInfo?.email}</div>
        </div>
      </div>

      <div>
        <button
          onClick={() => {
            dispatch(updateLogout());
            navigate("/login");
          }}
          className=" mt-6 rounded-md  h-[30px] p-1 px-1 text-sm font-normal bg-[#F11F4C] text-[#EDEDFF]"
        >
          Logout
        </button>
      </div>
    </div>
  );
}
