import React from "react";
import headerBody from "../../../assets/about.png";

import icon1 from "../../../assets/logo1.png";
import icon2 from "../../../assets/logo2.png";
import icon3 from "../../../assets/logo3.png";
import icon4 from "../../../assets/logo4.png";
import icon5 from "../../../assets/logo5.png";
import icon6 from "../../../assets/logo6.png";
import { FaPlay } from "react-icons/fa6";

export default function MidBody() {
  return (
    <div className="pl-56 pt-25">
      <div className="flex flex-row justify-between pt-20 ">
        <div className="flex flex-col justify-start w-[742px]">
          <div className="text-[#083156]  font-normal text-xs tracking-[2%] pt-16">
            <div className="bg-[#FFD9AB] px-3 py-2 rounded-full w-[60px]">
              About
            </div>
          </div>
          <div className="pt-2 text-[#083156] font-extrabold text-4xl tracking-[2%]">
            Our Values
          </div>
          <div className=" pt-6 text-[#083156] font-normal text-base tracking-[4%] break-before-left">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </div>
        </div>

        <div>
          <div className="flex items-center  justify-center">
            <img className="h-[436px] " src={headerBody} alt="Header Body" />
          </div>
        </div>
      </div>

      {/* <div className=" flex flex-row justify-between pt-16">
        <img src={icon1} alt="explore" />
        <img src={icon2} alt="explore" />
        <img src={icon3} alt="explore" />

        <img src={icon5} alt="explore" />
        <img src={icon6} alt="explore" />
        <img src={icon4} alt="explore" />
      </div>

      <div className="flex flex-row justify-between my-5">
        <div className="w-[400px]  mt-5    bg-[#FFC100] bg-opacity-5 p-2s h-[212.52px] p-[10.3px 16.31px]    gap-[19.74px] font-montserrat text-17 font-semibold leading-[27px] tracking-[0.02em] text-left">
          <div className="p-4">
            <div className="  text-[#083156] py-2 text-[53px] font-bold">
              Why choose
            </div>
            <div className="  text-[#083156] py-5 text-[53px] font-bold">
              our classes
            </div>

            <div className="text-17 pt-3  font-medium text-sm text-[#656565] leading-[27px] tracking-[0.02em] text-left">
              Mountains in just switching city steps Might rung line what Was or
              between towards the have phase.
            </div>
          </div>
        </div>
        <div className="w-[400px] my-5 p-5  bg-[#FFC100] bg-opacity-5 p-2s h-[212.52px] p-[10.3px 16.31px]    gap-[19.74px] font-montserrat text-17 font-semibold leading-[27px] tracking-[0.02em] text-left">
          <div className="p-4">
            <div className=" pt-2">
              <FaPlay color="#FFC100" size={25} />
            </div>
            <div className="  text-[#FFC100] py-2 text-2xl font-bold">
              Occasional Video Update
            </div>

            <div className="text-17 pt-2 font-medium text-base text-[#FFC100] leading-[27px] tracking-[0.02em] text-left">
              Mountains in just switching city steps Might rung line what Was or
              between towards the have phase.
            </div>
          </div>
        </div>
        <div className="w-[400px] my-5 p-5  bg-[#FFC100] bg-opacity-5 p-2s h-[212.52px] p-[10.3px 16.31px]    gap-[19.74px] font-montserrat text-17 font-semibold leading-[27px] tracking-[0.02em] text-left">
          <div className="p-4">
            <div className=" pt-2">
              <FaPlay color="#083156" size={25} />
            </div>
            <div className="  text-[#083156] py-2 text-2xl font-bold">
              Online Course From Experts{" "}
            </div>

            <div className="text-17 pt-2 font-medium text-base text-[#656565] leading-[27px] tracking-[0.02em] text-left">
              Mountains in just switching city steps Might rung line what Was or
              between towards the have phase.
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="w-[400px] my-5 p-5  bg-[#FFC100] bg-opacity-5 p-2s h-[212.52px] p-[10.3px 16.31px]    gap-[19.74px] font-montserrat text-17 font-semibold leading-[27px] tracking-[0.02em] text-left">
          <div className="p-4">
            <div className=" pt-2">
              <FaPlay color="#083156" size={25} />
            </div>
            <div className="  text-[#083156] py-2 text-2xl font-bold">
              Class Program Options
            </div>

            <div className="text-17 pt-2 font-medium text-base text-[#656565] leading-[27px] tracking-[0.02em] text-left">
              Mountains in just switching city steps Might rung line what Was or
              between towards the have phase.
            </div>
          </div>
        </div>
        <div className="w-[400px] my-5 p-5  bg-[#FFC100] bg-opacity-5 p-2s h-[212.52px] p-[10.3px 16.31px]    gap-[19.74px] font-montserrat text-17 font-semibold leading-[27px] tracking-[0.02em] text-left">
          <div className="p-4">
            <div className=" pt-2">
              <FaPlay color="#083156" size={25} />
            </div>
            <div className="  text-[#083156] py-2 text-2xl font-bold">
              500+ High Quality Courses
            </div>

            <div className="text-17 pt-2 font-medium text-base text-[#656565] leading-[27px] tracking-[0.02em] text-left">
              Mountains in just switching city steps Might rung line what Was or
              between towards the have phase.
            </div>
          </div>
        </div>
        <div className="w-[400px] my-5 p-5  bg-[#FFC100] bg-opacity-5 p-2s h-[212.52px] p-[10.3px 16.31px]    gap-[19.74px] font-montserrat text-17 font-semibold leading-[27px] tracking-[0.02em] text-left">
          <div className="p-4">
            <div className=" pt-2">
              <FaPlay color="#083156" size={25} />
            </div>
            <div className="  text-[#083156] py-2 text-2xl font-bold">
              Earn a Certificate
            </div>

            <div className="text-17 pt-2 font-medium text-base text-[#656565] leading-[27px] tracking-[0.02em] text-left">
              Mountains in just switching city steps Might rung line what Was or
              between towards the have phase.
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
