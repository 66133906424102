import React from "react";
import { useNavigate } from "react-router-dom";

export default function TopBodyStrength() {
  const navigate = useNavigate();

  const onNagigation = () => {
    navigate("/enroll");
  };

  return (
    <div className="px-20 ">
      {/* <div className="flex flex-row justify-between">{renderPartners()} </div> */}
      <div className="flex flex-row justify-between"> </div>

      <div className="flex flex-row justify-between pt-18 py-6">
        <div className="w-[377.69px]  bg-white p-2s h-[198.52px] p-[10.3px 16.31px] border-2 border-solid   gap-[19.74px] font-montserrat text-17 font-semibold leading-[27px] tracking-[0.02em] text-left">
          <div className="p-4">
            <div className="text-[#186F65] text-lg font-semibold">
              Quality Education
            </div>

            <div className="text-17 pt-2 font-normal leading-[27px] tracking-[0.02em] text-left">
              Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
              eiusmod tempor ut labore.
            </div>
            <button className="w-[123.73px] h-[30.43px] mt-3 p-[1.72px 6.87px] bg-[#186F65] rounded-full gap-[8.58px] font-montserrat text-17 font-normal leading-[27px] tracking-[0.02em] text-center text-[#FFC100]">
              View Course
            </button>
          </div>
        </div>
        <div className="w-[377.69px]  bg-white p-2s h-[198.52px] p-[10.3px 16.31px] border-2 border-solid   gap-[19.74px] font-montserrat text-17 font-semibold leading-[27px] tracking-[0.02em] text-left">
          <div className="p-4">
            <div className="text-[#186F65] text-lg font-semibold">
              Experienced Teachers
            </div>

            <div className="text-17 pt-2 font-normal leading-[27px] tracking-[0.02em] text-left">
              Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
              eiusmod tempor ut labore.
            </div>
            <button className="w-[123.73px] h-[30.43px] mt-3 p-[1.72px 6.87px] bg-[#186F65] rounded-full gap-[8.58px] font-montserrat text-17 font-normal leading-[27px] tracking-[0.02em] text-center text-[#FFC100]">
              View Course
            </button>
          </div>
        </div>
        <div className="w-[377.69px]  bg-white p-2s h-[198.52px] p-[10.3px 16.31px] border-2 border-solid   gap-[19.74px] font-montserrat text-17 font-semibold leading-[27px] tracking-[0.02em] text-left">
          <div className="p-4">
            <div className="text-[#186F65] text-lg font-semibold">
              Intership With Certificate
            </div>

            <div className="text-17 pt-2 font-normal leading-[27px] tracking-[0.02em] text-left">
              Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
              eiusmod tempor ut labore.
            </div>
            <button className="w-[123.73px] h-[30.43px] mt-3 p-[1.72px 6.87px] bg-[#186F65] rounded-full gap-[8.58px] font-montserrat text-17 font-normal leading-[27px] tracking-[0.02em] text-center text-[#FFC100]">
              View Course
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
