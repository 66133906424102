import React, { useState } from "react";
import logoh from "../../assets/logoh.png";
import registerPhoto from "../../assets/register.png";
import { useNavigate } from "react-router-dom";
import useProfile from "../../hooks/userProfile";
import { toast } from "react-toastify";

export default function Register() {
  const navigate = useNavigate();

  // State variables to store input data for each text field
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { createNewUserProfile } = useProfile();

  const createUser = async () => {
    const payload = {
      username: firstName.trim() + " " + lastName.trim(),
      password: password,
      confirm_password: confirmPassword,
      phone: phoneNumber,
      email: email,
    };
    if (password == confirmPassword) {
      const data = await createNewUserProfile(payload);
       
    } else {
      toast.error("Password is Not Matching");
    }
  };
  return (
    <div className="bg-[#FFFAED] px-4 md:px-10 lg:px-20 py-8 md:py-12 lg:py-20">
      <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
        <div className="mb-4 md:mb-0">
          <img src={logoh} alt="logo" />
          <div className="font-semibold text-2xl text-[#555555] ">
            Register into account
          </div>
        </div>
      </div>
      <div className="flex  flex-row justify-between">
        <div className="flex flex-row  flex-wrap w-1/2 h-[50px]  justify-between items-center gap-4 md:gap-6 lg:gap-8 mt-6">
          <TextField
            label="First Name :"
            value={firstName}
            onChange={(e: any) => setFirstName(e.target.value)}
          />
          <TextField
            label="Last Name :"
            value={lastName}
            onChange={(e: any) => setLastName(e.target.value)}
          />
          <TextField
            label="Email :"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <TextField
            label="Phone Number :"
            value={phoneNumber}
            onChange={(e: any) => setPhoneNumber(e.target.value)}
          />
          <TextField
            label="Password :"
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
          <TextField
            label="Confirm Password :"
            value={confirmPassword}
            onChange={(e: any) => setConfirmPassword(e.target.value)}
          />
          <button
            onClick={() => {
              createUser();
            }}
            className="bg-[#FF725E] rounded-md h-[40px] w-[280px] mt-10 "
          >
            <div className="text-center font-semibold text-sm text-white ">
              Signup
            </div>
          </button>

          <button
            onClick={() => {
              navigate("/login");
            }}
            className=" rounded-md h-[40px] w-[280px] mt-4 border-2 border-[#FF725E] "
          >
            <div className="text-center font-semibold text-sm text-[#FF725E] ">
              Back to login
            </div>
          </button>
        </div>
        <div className="hidden md:block w-1/2">
          <img src={registerPhoto} alt="register" />
        </div>
      </div>
    </div>
  );
}

function TextField({ label, value, onChange }: any) {
  return (
    <div className="flex flex-col w-full md:w-[calc(50%-1rem)]">
      <div className="font-normal text-sm">{label}</div>
      <input
        type="text"
        className="w-full h-12 md:h-[43px] bg-gray-200 rounded-md px-2"
        style={{ backgroundColor: "#EEEEEE" }}
        placeholder={`Enter your ${label.replace(" :", "")}`}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
