import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { updateLogin } from "../redux/slices/user";
import { toast } from "react-toastify";
import { updateCourses, updatePurchageCourses } from "../redux/slices/course";

function usePurchase() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userAccessToken = useAppSelector((state) => state.user.token);

  const createPurchase = async (payload: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/api/v1/purchase/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      if (data.purchaseCourse) {
        toast.success("Purchagse Successfully");
      }
      return data;
    } catch (error) {
      toast.error(" Purchase Failed");
      return error;
    }
  };

  const getPurchageById = async (payload: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/api/v1/purchase/getbyId`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );

      if (data.purchaseCourse) {
        dispatch(updatePurchageCourses(data.purchaseCourse));
      }

      return data;
    } catch (error) {
      toast.error("Failed login");
      return error;
    }
  };

  return {
    createPurchase,
    getPurchageById,
  };
}

export default usePurchase;
