import React from "react";
import EnrollTopHeader from "../../enroll/enrollComponents/enrollHeader";
import WebinarHeader from "./webinarHeader";
import WebinarDetailBody from "./webinardetailsBody";

export default function WebinarDetails() {
  return (
    <div className="bg-[#FFFAED]">
      <EnrollTopHeader />
      <div className="bg-[#FEEBD3] pt-20 px-20">
        <WebinarHeader />
        <WebinarDetailBody />
      </div>
    </div>
  );
}
