import { SettingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import type { CollapseProps } from "antd";
import { Collapse, Select } from "antd";
import { useAppSelector } from "../../../redux/hooks";
import useProgress from "../../../hooks/useProgress";
import { toast } from "react-toastify";
import { FaLock } from "react-icons/fa";
import { ImUnlocked } from "react-icons/im";
import { useDispatch } from "react-redux";
import YoutubeVideo from "../../../components/youtubeVideo";

const { Option } = Select;

export default function DetailContent({ contentId }: any) {
  const dispatch = useDispatch();
  const isUser = useAppSelector((state: any) => state.user.isAuth);
  const userId = useAppSelector((state) => state?.user.data?.id);
  const paidProgrssContent = useAppSelector(
    (state) => state.course.paidCourseProgress
  );
  const currentCourse = useAppSelector(
    (state) => state.program.programDetails.item
  );

  const { createProgress } = useProgress();
  const courseContentData = useAppSelector(
    (state) => state.course.currentCourseContent
  );
  const isPaid = useAppSelector(
    (state) => state.course.iscurrentCourseContentPaid
  );
  // const [courseContentData, setCourseContentData] = useState(courseContent);
  const [isPaidCourse, setIsPaidCourse] = useState(isPaid);

  // useEffect(() => {
  //   // setCourseContentData(courseContent);
  //   // setIsPaidCourse(isPaid);
  // }, []);

  const [expandIconPosition, setExpandIconPosition] = useState<any>("end");

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const items: CollapseProps["items"] = courseContentData.sectionsDetails.map(
    (item: any, index: any) => {
      return {
        key: index + 1,
        label: (
          <div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row justify-start gap-x-1 items-center">
                {isPaid &&
                paidProgrssContent[courseContentData.courseId].filter(
                  (item: any) => item.moduleId == index
                ).length > 0 ? (
                  <ImUnlocked color="green" size={10} />
                ) : (
                  <FaLock size={10} />
                )}
                <div className="pl-2">{item.title}</div>
              </div>
              <div className="font-semibold text-[8px]">
                {isPaid &&
                  paidProgrssContent[courseContentData.courseId].filter(
                    (item: any) => item.moduleId == index
                  ).length}
                /5 (
                {isPaid &&
                  paidProgrssContent[courseContentData.courseId]
                    .filter((item: any) => item.moduleId == index)
                    .reduce(
                      (total: any, item: any) => total + item.progressValue,
                      0
                    ) > 0 &&
                  paidProgrssContent[courseContentData.courseId]
                    .filter((item: any) => item.moduleId == index)
                    .reduce(
                      (total: any, item: any) => total + item.progressValue,
                      0
                    )}
                %)
              </div>
            </div>
          </div>
        ),
        children: (
          <div>
            {item.lectures.map((itemHeader: any, idx: any) => (
              <div key={idx}>
                <button
                  onClick={() => {
                    if (isUser) {
                      // if()
                      if (isPaid) {
                        createProgress({
                          userId,
                          courseId: courseContentData.courseId,
                          contentId: courseContentData.contentId,
                          progressValue: 4,
                          moduleId: index,
                          subModuleId: idx,
                          sectionCompleted: " ",
                          lectureCompleted: " ",
                          isCompleted: false,
                        });
                      } else {
                        toast.warning("first purchase the course");
                      }
                    } else {
                      toast.warning("first login");
                    }
                  }}
                  key={idx}
                  className="flex flex-row justify-between py-1 w-full"
                >
                  <div className="flex flex-row gap-x-1 items-center">
                    <div>
                      {isPaid &&
                      paidProgrssContent[courseContentData.courseId]
                        .filter((item: any) => item.moduleId == index)
                        .filter((item: any) => item.subModuleId == idx)
                        .length == 1 ? (
                        <ImUnlocked color="green" size={10} />
                      ) : (
                        <FaLock size={10} />
                      )}
                    </div>
                    <span className="text-[#263238]">{itemHeader.title}</span>
                  </div>
                  <div className="rounded-full bg-[#263238] bg-opacity-20 px-2 py-1">
                    <div>{itemHeader.duration}</div>
                  </div>
                </button>
                <div key={idx}>
                  <YoutubeVideo
                    islock={
                      isPaid &&
                      paidProgrssContent[courseContentData.courseId]
                        .filter((item: any) => item.moduleId == index)
                        .filter((item: any) => item.subModuleId == idx)
                        .length == 1
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        ),
      };
    }
  );

  return (
    <div className="flex flex-col items-start">
      <div className="font-normal text-2xl pt-10">This course includes</div>
      <div className="flex flex-row justify-between pt-6">
        <ol className="list-none">
          <li className="flex items-center">
            <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2"></div>
            <span className="text-[#263238]">
              {courseContentData.featureInclude[0]}
            </span>
          </li>
          <li className="flex items-center">
            <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2"></div>
            <span className="text-[#263238]">
              {courseContentData.featureInclude[1]}
            </span>
          </li>
          <li className="flex items-center">
            <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2"></div>
            <span className="text-[#263238]">
              {courseContentData.featureInclude[2]}
            </span>
          </li>
        </ol>
        <div className="w-[60px]"></div>
        <ol className="list-none">
          <li className="flex items-center">
            <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2"></div>
            <span className="text-[#263238]">Access on mobile and TV</span>
          </li>
          <li className="flex items-center">
            <div className="w-[8px] h-[8px] rounded-full bg-[#263238] mr-2"></div>
            <span className="text-[#263238]">Certificate of completion</span>
          </li>
        </ol>
      </div>

      <div className="font-normal text-2xl pt-10">Course Content</div>
      <div
        className="flex flex-row justify
-between pt-3"
      >
        <div className="text-[#263238]">8 sections</div>
        <div className="w-[3px] h-[20px] bg-[#263238] bg-opacity-70 mx-10"></div>
        <div className="text-[#263238]">41 lectures </div>
        <div className="w-[3px] h-[20px] bg-[#263238] bg-opacity-70 mx-10"></div>
        <div className="text-[#263238]">9h 29m total length</div>
      </div>
      <div className="w-[727px] py-6 pr-2">
        <Collapse
          defaultActiveKey={["1"]}
          onChange={onChange}
          expandIconPosition={expandIconPosition}
          items={items}
        />
        <br />
      </div>
    </div>
  );
}
