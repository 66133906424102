import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import webi from "../../../assets/webi.png";
import mentor from "../../../assets/mentor.png";
import { RiTimerFlashFill } from "react-icons/ri";
import { MdChromeReaderMode } from "react-icons/md";
import { IoTimerSharp } from "react-icons/io5";
import { FaHeadphones } from "react-icons/fa6";

export default function WebinarDetailBody() {
  const [hover, setHover] = React.useState<any>(-1);

  const labels: any = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  function getLabelText(value: any) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  const courseDetails = useAppSelector(
    (state: any) => state?.program.programDetails.item
  );

  return (
    <div className="  py-10">
      <div className=" flex flexx-row justify-between w-full">
        <div>
          <div className="">
            <div
              className="text-[27px] font-medium text-ellipsis
           text-[#186F65] "
            >
              Advanced Stock Trading Course + Strategies{" "}
              {/* {courseDetails.courserName} */}
            </div>
            <div className="text-base font-normal text-[#7D8B98]   w-[600px] ">
              {/* {courseDetails.des} */}
              Lorem ipsum dolor sit amet consectetur adipiscingLorem ipsum dolor
              sit amet consectetur adipiscingLorem ipsum dolor sit
            </div>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <div className="">
              <img
                className="  object-cover   w-[93px] h-[107px]"
                src={mentor}
              />
              <div className="text-[#6A6B6C] font-medium text-lg">by</div>

              <div className="text-[#6A6B6C] font-medium text-lg">
                vanjinathan
              </div>
              <div className="text-[#6A6B6C] font-medium text-lg">
                ai professor{" "}
              </div>
            </div>
            <div className=" flex flex-col justify-between ">
              <div className="flex flex-row ">
                <RiTimerFlashFill color="#186F65" className="m-2" />

                <div className="text-[#6A6B6C] font-medium text-lg">
                  date and time
                </div>
              </div>
              <div className="flex flex-row ">
                <MdChromeReaderMode color="#186F65" className="m-2" />

                <div className="text-[#6A6B6C] font-medium text-lg">mode </div>
              </div>
              <div className="flex flex-row ">
                <IoTimerSharp color="#186F65" className="m-2" />

                <div className="text-[#6A6B6C] font-medium text-lg">
                  duration
                </div>
              </div>
              <div className="flex flex-row ">
                <FaHeadphones color="#186F65" className="m-2" />

                <div className="text-[#6A6B6C] font-medium text-lg">
                  requirement
                </div>
              </div>
            </div>
            <div className=" flex flex-col justify-between ">
              <div className="flex flex-row ">
                <div className="text-[#6A6B6C] font-medium text-lg">
                  02/02/2022 : 10am finished
                </div>
              </div>
              <div className="flex flex-row ">
                <div className="text-[#6A6B6C] font-medium text-lg">
                  free & live{" "}
                </div>
              </div>
              <div className="flex flex-row ">
                <div className="text-[#6A6B6C] font-medium text-lg">
                  1 hr :30 min
                </div>
              </div>
              <div className="flex flex-row ">
                <div className="text-[#6A6B6C] font-medium text-lg">
                  headphone / online
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[372px]">
          <img
            className="  object-cover  rounded-lg  w-[372px] h-[214px]"
            src={webi}
          />
          <button className="w-[380px] h-[40px] my-2 bg-transparent border-2 border-solid  border-[#186F65] text-[#186F65] rounded-full   ">
            Share
          </button>
          <button className="w-[380px] h-[40px] my-2   border-2 border-solid  bg-[#186F65] text-white rounded-full  ">
            Play
          </button>
        </div>
      </div>
    </div>
  );
}
