import React, { useState } from "react";
import TopNavBar from "../dashboard/dashboardComponents/topNavBar";
import logoh from "../../assets/logoh.png";
import program from "../../assets/programdetail.png";
import { CiMicrophoneOn } from "react-icons/ci";
import { GiDuration } from "react-icons/gi";
import { MdOutlineVisibility } from "react-icons/md";

export default function ProgramDetailPage() {
  return (
    <div>
      <div className="px-20 py-6 justify-between bg-[#FFFAED]">
        <div className=" bg-white flex flex-row min-w-full p-2">
          <div>
            <img src={logoh} />
          </div>
          <div className="w-full pl-4">
            <TopNavBar getCurrentPage={"program"} />
          </div>
        </div>
        <div className="my-10 bg-[#FEEBD3] mx-20 h-[445px] ">
          <div className="flex flex-row justify-start  ">
            <div className="p-12 ">
              <img src={program} alt="program" />
            </div>
            <div className="  w-[455px] pt-12">
              <div className="flex flex-col justify-start ">
                <div className="  text-1 font-semibold text-2xl">
                  Get your trading superpowers
                </div>
                <div className="text-[13px] font-normal py-10 ">
                  Vivamus gravida molestie ante a placerat. Nam semper in quam
                  eget iaculis. Nullam ornare vulputate tellus et pharetra.
                  Mauris sem magna, feugiat eget quam sed, hendrerit scelerisque
                  mi. In bibendum sit amet nulla
                </div>
              </div>
              <div className="h-[60px] w-[300px] bg-white flex flex-row  ">
                <div className="flex justify-center items-center h-[43px] w-[136px]   text-[17px] font-normal">
                  <div className="flex flex-row gap-x-2   justify-center items-center">
                    <div>
                      <CiMicrophoneOn />
                    </div>
                    <div>aravind</div>
                  </div>
                </div>
                <div className="flex justify-center items-center h-[43px] w-[136px]   text-[17px] font-normal">
                  <div className="flex flex-row gap-x-2   justify-center items-center">
                    <div>
                      <GiDuration />
                    </div>
                    <div> 11:40</div>
                  </div>
                </div>{" "}
                <div className="flex justify-center items-center h-[43px] w-[136px]   text-[17px] font-normal">
                  <div className="flex flex-row gap-x-2   justify-center items-center">
                    <div>
                      <MdOutlineVisibility />
                    </div>
                    <div> 11.3k</div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-start py-10 gap-x-4">
                <div className="font-bold text-2xl">Free</div>
                <div className="text-[#FFC800] border-2 px-2  border-[#FFC800]">
                  Book now
                </div>
                <div className="text-[13px] font-bold underline ">
                  More Details
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="gap-2 items-center justify-center flex flex-col ">
          <div className="  text-black text-[24px] font-bold py-10">
            Booking
          </div>
          <input
            type="text"
            className="w-[438px] h-[46px] rounded-full bg-[#FEEBD3] px-4"
            placeholder="Name"
          />
          <input
            type="text"
            className="w-[438px] h-[46px] rounded-full bg-[#FEEBD3] px-4"
            placeholder="Phone"
          />
          <input
            type="text"
            className="w-[438px] h-[46px] rounded-full bg-[#FEEBD3] px-4"
            placeholder="Mail"
          />
          <input
            type="text"
            className="w-[438px] h-[46px] rounded-full bg-[#FEEBD3] px-4"
            placeholder="Qty"
          />
          <div className="w-[70px] h-[33px] bg-[#186F65] text-white flex items-center justify-center">
            Book
          </div>
        </div>
      </div>
    </div>
  );
}
