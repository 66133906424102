import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { updateLogin } from "../redux/slices/user";
import { toast } from "react-toastify";
import { updateCourses } from "../redux/slices/course";

function useCourse() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userAccessToken = useAppSelector((state) => state.user.token);

  const createCourses = async (userData: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/api/v1/course/create`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      if (data) {
        toast.success("Course  Created Successfully");
      }
      return data;
    } catch (error) {
      toast.error("Failed");
      return error;
    }
  };

  const getAllCourses = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_hostName}/api/v1/course/getAll`,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      console.log("userAccessToken", userAccessToken, data.courses);
      if (data.courses) {
        dispatch(updateCourses(data.courses));
      }
      return data;
    } catch (error) {
      toast.error("Failed login");
      return error;
    }
  };

  const getCourseById = async (payload: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/api/v1/course/getbyId`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );

      return data;
    } catch (error) {
      toast.error("Faile");
      return error;
    }
  };

  const getSearchCourse = async (payload: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/api/v1/course/search`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );

      return data;
    } catch (error) {
      toast.error("Faile");
      return error;
    }
  };

  return {
    createCourses,
    getAllCourses,
    getCourseById,
    getSearchCourse,
  };
}

export default useCourse;
