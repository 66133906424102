import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GoogleAuthState {
  GoogleAuthDetails: GoogleAuth;
  tokenList: any;
  userInfo: any;
  theme: string;
  loading: boolean;
  pageLocation: string;
  userType: string;
  userIam: any;
}
export interface GoogleAuth {
  clientId: string;
  credential: string;
  select_by: string;
}

export const initialGlobalState: GoogleAuthState = {
  GoogleAuthDetails: {
    clientId: " ",
    credential: " ",
    select_by: " ",
  },
  tokenList: [],
  userInfo: {},
  theme: "light",
  loading: true,
  pageLocation: "Dashboard",
  userType: "root",
  userIam: {},
};

export const User = createSlice({
  name: "user",
  initialState: initialGlobalState,
  reducers: {
    userTokenData: (state, action: PayloadAction<GoogleAuth>) => {
      state.GoogleAuthDetails = action.payload;
    },
    updateUserTokenData: (state, { payload }: PayloadAction<any>) => {
      state.tokenList.push(payload);
    },
    updateUserInfo: (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload;
    },
    updateTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updatePageLocation: (state, action: PayloadAction<string>) => {
      state.pageLocation = action.payload;
    },
    updateUserType: (state, action: PayloadAction<string>) => {
      state.userType = action.payload;
    },
    updateUserIam: (state, action: PayloadAction<any>) => {
      state.userIam = action.payload;
    },
  },
});
export const {
  userTokenData,
  updateUserTokenData,
  updateUserInfo,
  updateTheme,
  updateLoading,
  updatePageLocation,
  updateUserType,
  updateUserIam,
} = User.actions;

export default User.reducer;
