import React from "react";
import EnrollTopHeader from "./enrollComponents/enrollHeader";
import EnrollTopBody from "./enrollComponents/enrollTopBody";
import EnrollCourse from "./enrollComponents/exploreCourse";
import EnrollBottom from "./enrollComponents/enrollBottom";
import SearchBox from "../../components/seacchBox";

export default function EnrollDashboard() {
  return (
    <div className="bg-[#FFFAED]">
      <EnrollTopHeader />
      <div className="pt-10">
        <EnrollTopBody />
        <EnrollCourse />
        <EnrollBottom />
      </div>
    </div>
  );
}
