import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoMdPerson } from "react-icons/io";
import SearchField from "../../../components/searchafeild";
import logoh from "../../../assets/logoh.png";
import { useAppSelector } from "../../../redux/hooks";
import useCourse from "../../../hooks/useCourse";
import { useDispatch } from "react-redux";
import { addTocart, updateItem } from "../../../redux/slices/program";
import { updateIscurrentCourseContentPaid } from "../../../redux/slices/course";
import useCourseContent from "../../../hooks/useCourseContent";
import { useNavigate } from "react-router-dom";
import { BsCartPlus } from "react-icons/bs";

export default function TopHeader() {
  const isUser = useAppSelector((state: any) => state.user.isAuth);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchData, setSearchData] = useState([]);
  const { getSearchCourse } = useCourse();
  const handleSearchChange = (newValue: any) => {
    setSearchValue(newValue);
    // You can perform additional actions here based on the new search value
  };
  const { getCourseContentById } = useCourseContent();

  const courseContentData = async (contentId: any) => {
    await getCourseContentById(contentId);
  };

  const getSearchResult = async () => {
    const data = await getSearchCourse({ search: searchValue });

    if (data.searchData) {
      setSearchData(data.searchData);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Make API call only if searchText is not empty
      if (searchValue.trim() !== "") {
        // Replace 'your-api-endpoint' with your actual API endpoint
        getSearchResult();
      } else {
        // Clear search results if searchText is empty
        setSearchData([]);
      }
    }, 500); // Debounce delay of 500 milliseconds

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const renderContainer = () => {
    const containers: any = [];

    searchData.map((item: any, i: any) => {
      // Added parentheses here
      containers.push(
        <div>
          <div
            key={i}
            className="relative bg-white  rounded-xl flex flex-row justify-between m-2"
          >
            <button
              onClick={() => {
                dispatch(updateItem(item));
                courseContentData({ courseId: item.courseId });
                dispatch(updateIscurrentCourseContentPaid(false));

                if (item.type === "bundle") {
                  navigate("/bundle");
                } else if (item.type === "blog") {
                  navigate("/blog");
                } else if (item.type === "webinar") {
                  navigate("/webinar");
                } else {
                  navigate("/details");
                }
              }}
              className="flex flex-row justify-center"
            >
              <img
                className="h-[100px] w-[150px] p-1"
                src={item.url}
                alt="course "
              />
            </button>

            <div>
              {(item.type == "coursesLibrary" || item.type == "free") && (
                <div className="font-poppins py-1 pl-4 text-xl font-semibold leading-15 text-left">
                  {item.courserName}
                </div>
              )}
              {item.type == "blog" && (
                <div className="px-2">
                  <div className="font-poppins py-1 w-[282px]  font-semibold text-base  text-[#186F65]">
                    How To Identify Good Stocks [2024]: Explained For Beginners
                  </div>
                  <div className="font-poppins py-1 w-[282px]   font-normal text-xs  text-[#B5CB99]">
                    Starting the journey of stock investing presents a crucial
                    decision for beginners. Either one can rely on expensive
                    expert advice
                  </div>
                </div>
              )}
              {item.type == "coursesLibrary" && (
                <div className="flex flex-row justify-between pl-4">
                  <div className="flex flex-row justify-start">
                    <div className="font-poppins py-1 text-sm font-semibold leading-15 text-left">
                      {item.price}
                    </div>
                    <div className="font-poppins py-1 pl-1 mt-1 items-baseline text-xs font-semibold leading-15 text-left text-[#827A7A] line-through">
                      {item.discount}
                    </div>
                    <div className="font-poppins py-1 pl-4 text-xs font-semibold leading-15 text-left text-[#827A7A]">
                      {item.lession} lessons
                    </div>
                  </div>

                  <div className="font-poppins py-1 pl-1 pr-1 items-baseline text-xs font-semibold leading-15 text-left text-[#827A7A]">
                    {item.rating} Rating
                  </div>
                </div>
              )}

              {item.type == "free" && (
                <div>
                  <div className="flex felx-row  justify-between  py-3 px-4">
                    <button
                      onClick={() => {}}
                      className="px-4 flex flex-row items-center justify-center h-[28px] gap-[12.16px] text-sm font-bold border bg-[#083156] border-[#083156] text-white rounded hover:bg-[#083156] hover:text-white transition-all duration-500"
                    >
                      <div>Free</div>
                    </button>
                  </div>
                </div>
              )}
            </div>

            {(item.type == "coursesLibrary" || item.type == "free") && (
              <div className="   py-3 px-4">
                <div className="  justify-start flex flex-row ">
                  <IoMdPerson color="#6A6B6C" />
                  <div className="font-poppins pl-2 text-sm font-semibold leading-15 text-left text-[#827A7A]">
                    {item.people} Student
                  </div>
                </div>

                <button
                  onClick={() => {
                    dispatch(addTocart(item));
                  }}
                  className="px-4 flex flex-row items-center justify-center h-[28px] gap-[12.16px] text-sm font-bold border bg-[#083156] border-[#083156] text-white rounded hover:bg-[#083156] hover:text-white transition-all duration-500"
                >
                  <div>Add to Cart</div>
                  <BsCartPlus color="#FFFFFF" size={18} />
                </button>
              </div>
            )}
          </div>
        </div>
      );
    });

    return containers;
  };

  return (
    <div className="px-20 pt-5  fixed w-full">
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-row justify-start">
          <div>
            <img src={logoh} />
          </div>

          <div className="pl-2 relative">
            <SearchField
              value={searchValue}
              onChangeCallback={handleSearchChange}
            />
            {searchValue !== "" && (
              <div className="bg-white h-full w-1/2 fixed  overflow-y-auto ">
                <div>{renderContainer()}</div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-end gap-x-4 items-center ">
          <div className=" flex flex-row justify-start">
            <div className="text-[#083156] font-normal text-sm">Support</div>
            <IoIosArrowDown size={20} className="pt-1" />
          </div>
          <div className="flex flex-row justify-start ">
            <div className="text-[#083156] font-normal text-sm">Company</div>
            <IoIosArrowDown size={20} className="pt-1" />
          </div>
          <div className=" flex flex-row justify-start">
            <div className="text-[#083156] font-normal text-sm">
              Site Language
            </div>
            <IoIosArrowDown size={20} className="pt-1" />
          </div>
        </div>
      </div>
      <div className="border-t-2  my-2 border-solid border-[#999696] w-18%"></div>
    </div>
  );
}
