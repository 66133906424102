import React, { useState } from "react";

const CustomDropdownMenu = ({
  options,
  selectedValue,
  onValueChange,
  width,
}: any) => {
  const [currentValue, setCurrentValue] = useState();
  return (
    <select
      style={{ width: width, height: "33px" }}
      className=" px-2 rounded-sm border-2 border-[#186F65]  text-[#186F65] text-sm font-medium"
      value={selectedValue}
      onChange={(e) => onValueChange(e.target.value)}
    >
      {options.map((option: any) => (
        <option className="h-6 py-2" key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default CustomDropdownMenu;
