import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Course {
  courses: any;
  currentCourseContent: any;
  currentCourse: any;
  purchaseCourse: any;
  iscurrentCourseContentPaid: any;
  currentCourseProgress: any;
  paidCourseProgress: any;
}

export const initialUserState: Course = {
  courses: [],
  currentCourseContent: {},
  currentCourse: {},
  purchaseCourse: [],
  iscurrentCourseContentPaid: false,
  currentCourseProgress: [],
  paidCourseProgress: {},
};

export const courseSlice = createSlice({
  name: "course",
  initialState: initialUserState,
  reducers: {
    updateCourses: (state, action: PayloadAction<any>) => {
      state.courses = action.payload;
    },
    updatePurchageCourses: (state, action: PayloadAction<any>) => {
      state.purchaseCourse = action.payload;
    },
    updateIscurrentCourseContentPaid: (state, action: PayloadAction<any>) => {
      state.iscurrentCourseContentPaid = action.payload;
    },
    updateCurrentCourseProgress: (state, action: PayloadAction<any>) => {
      state.currentCourseProgress = action.payload;
    },
    updateCourseContent: (state, action: PayloadAction<any>) => {
      state.currentCourseContent = action.payload;
    },

    // add
    addPaidCourserProgrss: (state, action: PayloadAction<any>) => {
      const { courseId, courseProgress } = action.payload;
      console.log(" action.payload", courseId, courseProgress);
      state.paidCourseProgress = {
        ...state.paidCourseProgress,
        [courseId]: courseProgress,
      };
      // return {
      //   ...state,
      //   paidCourseProgress: {
      //     ...state.paidCourseProgress,
      //     [courseId]: courseProgress,
      //   },
      // };
    },
    updatePaidCourserProgrss: (state, action: PayloadAction<any>) => {
      const { courseId, courseProgress } = action.payload;
      state.paidCourseProgress[courseId].push(courseProgress);
    },
    updateCurrentCourse: (state, action: PayloadAction<any>) => {
      state.currentCourse = action.payload;
    },
  },
});

export const {
  updateCourses,
  updatePurchageCourses,
  updateIscurrentCourseContentPaid,
  updateCourseContent,
  addPaidCourserProgrss,
  updatePaidCourserProgrss,
  updateCurrentCourse,
} = courseSlice.actions;

export default courseSlice.reducer;
