import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { IconButton } from "@mui/material";
import { FaPlay } from "react-icons/fa6";
import { IoMdPerson } from "react-icons/io";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
// import enroll from "../../../assets/photo2.png";
import StarIcon from "@mui/icons-material/Star";
import { GiDuration } from "react-icons/gi";
import { AiFillProject } from "react-icons/ai";
import { PiCertificateBold } from "react-icons/pi";
import { PiCurrencyInrBold } from "react-icons/pi";

export default function BlogHeader() {
  const [hover, setHover] = React.useState<any>(-1);

  const courseDetails = useAppSelector(
    (state: any) => state?.program.programDetails.item
  );

  return (
    <div className="px-20 py-10">
      <div className=" flex flexx-row justify-between  h-[250px] ">
        <div className=" w-1/2 bg-white p-10">
          <div
            className="text-[30px] font-bold text-ellipsis
           text-[#2D3748] "
          >
            long established
          </div>
          <div
            className="text-sm font-normal text-[#718096
]   "
          >
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that....
          </div>
          <div className="flex flex-row justify-between pt-5">
            <div className="text-sm font-normal text-[#718096]   ">
              May 20th 2020
            </div>
            <div className="text-sm font-normal text-[#718096]   ">
              Read More
            </div>
          </div>
        </div>
        <img
          className="  object-cover  rounded-lg  w-1/2 "
          src={courseDetails.url}
          alt={courseDetails.courserName}
        />
      </div>
    </div>
  );
}
