import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaCartPlus } from "react-icons/fa";
import { useAppSelector } from "../../../redux/hooks";
import { CgProfile } from "react-icons/cg";
import { useDispatch } from "react-redux";

export default function TopNavBar({ getCurrentPage }: any) {
  // const [currentButton, setCurrentButton] = React.useState<any>("Home");

  const urlParams = window.location.pathname;

  const navigate = useNavigate();
  const cart = useAppSelector((state) => state?.program.cart);
  const currentButtonStatus = useAppSelector(
    (state) => state?.program.currentButton
  );

  const dispatch = useDispatch();
  const [currentButton, setCurrentButton] = useState("Home");
  const isUser = useAppSelector((state: any) => state.user.isAuth);

  // useEffect(() => {
  //   console.log("urlParams", urlParams);
  //   if (currentButton == "Courses") {
  //     navigate("/courses");
  //   }

  //   console.log("currentButton", currentButton);
  // }, [currentButton]);

  // useEffect(() => {
  // if (currentButton == "Courses") {
  //   navigate("/courses");
  // }
  // if (currentButton == "Home") {
  //   navigate("/home");
  // }
  // }, [currentButton]);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div className=" flex flex-row justify-start">
          <div className="flex flex-row justify-start gap-x-6">
            <button
              onClick={() => {
                // dispatch(updateCurrentButton("Home"));

                navigate("/home");
              }}
            >
              <div
                className={
                  urlParams == "/home"
                    ? "flex flex-row items-center justify-start  h-8 px-2  border border-[#FFC800]"
                    : "flex flex-row items-center justify-start  h-8 px-2  border-none "
                }
              >
                <div
                  className={
                    urlParams == "/home"
                      ? "text-[#FFC800] font-medium text-sm"
                      : "text-[#186F65] font-medium text-sm"
                  }
                >
                  Home
                </div>
                <IoIosArrowDown
                  size={17}
                  className={
                    urlParams == "/home"
                      ? "px-1 text-[#FFC800]"
                      : "px-1 text-[#186F65] "
                  }
                />
              </div>
            </button>
            <button
              onClick={() => {
                // // dispatch(updateCurrentButton("Courses"));
                // setCurrentButton("Courses");
                navigate("/courses");
              }}
            >
              <div
                className={
                  urlParams == "/courses"
                    ? "flex flex-row items-center justify-start  h-8 px-2  border border-[#FFC800]"
                    : "flex flex-row items-center justify-start  h-8 px-2  border-none "
                }
              >
                <div
                  className={
                    urlParams == "/courses"
                      ? "text-[#FFC800] font-medium text-sm"
                      : "text-[#186F65] font-medium text-sm"
                  }
                >
                  Courses
                </div>
                <IoIosArrowDown
                  size={17}
                  className={
                    urlParams == "/courses"
                      ? "px-1 text-[#FFC800]"
                      : "px-1 text-[#186F65] "
                  }
                />
              </div>
            </button>
            <button
              onClick={() => {
                navigate("/program");
              }}
            >
              <div
                className={
                  urlParams == "/program"
                    ? "flex flex-row items-center justify-start  h-8 px-2  border border-[#FFC800]"
                    : "flex flex-row items-center justify-start  h-8 px-2  border-none "
                }
              >
                <div
                  className={
                    urlParams == "/program"
                      ? "text-[#FFC800] font-medium text-sm"
                      : "text-[#186F65] font-medium text-sm"
                  }
                >
                  Program
                </div>
                <IoIosArrowDown
                  size={17}
                  className={
                    urlParams == "/program"
                      ? "px-1 text-[#FFC800]"
                      : "px-1 text-[#186F65] "
                  }
                />
              </div>
            </button>
            {/* <button
              onClick={() => {
                // dispatch(updateCurrentButton("Pricing"));
                setCurrentButton("Pricing");
                // setCurrentButton("Pricing");
              }}
            >
              <div
                className={
                  currentButton === "Pricing"
                    ? "flex flex-row items-center justify-start  h-8 px-2  border border-[#FFC800]"
                    : "flex flex-row items-center justify-start  h-8 px-2  border-none "
                }
              >
                <div
                  className={
                    currentButton === "Pricing"
                      ? "text-[#FFC800] font-medium text-sm"
                      : "text-[#186F65] font-medium text-sm"
                  }
                >
                  Pricing
                </div>
                <IoIosArrowDown
                  size={17}
                  className={
                    currentButton === "Pricing"
                      ? "px-1 text-[#FFC800]"
                      : "px-1 text-[#186F65] "
                  }
                />
              </div>
            </button> */}
            <button
              onClick={() => {
                navigate("/community");
              }}
            >
              <div
                className={
                  urlParams == "/community"
                    ? "flex flex-row items-center justify-start  h-8 px-2  border border-[#FFC800]"
                    : "flex flex-row items-center justify-start  h-8 px-2  border-none "
                }
              >
                <div
                  className={
                    urlParams == "/community"
                      ? "text-[#FFC800] font-medium text-sm"
                      : "text-[#186F65] font-medium text-sm"
                  }
                >
                  Live Community
                </div>
                <IoIosArrowDown
                  size={17}
                  className={
                    urlParams == "/community"
                      ? "px-1 text-[#FFC800]"
                      : "px-1 text-[#186F65] "
                  }
                />
              </div>
            </button>
            <button
              onClick={() => {
                navigate("/news");
                // dispatch(updateCurrentButton("Whats new"));
              }}
            >
              <div
                className={
                  urlParams == "/news"
                    ? "flex flex-row items-center justify-start  h-8 px-2  border border-[#FFC800]"
                    : "flex flex-row items-center justify-start  h-8 px-2  border-none "
                }
              >
                <div
                  className={
                    urlParams == "/news"
                      ? "text-[#FFC800] font-medium text-sm"
                      : "text-[#186F65] font-medium text-sm"
                  }
                >
                  What's new
                </div>
                <IoIosArrowDown
                  size={17}
                  className={
                    urlParams == "/news"
                      ? "px-1 text-[#FFC800]"
                      : "px-1 text-[#186F65] "
                  }
                />
              </div>
            </button>
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <button
            onClick={() => {
              navigate("/cart");
            }}
          >
            <div className="font-light text-xs text-[#186F65] pl-2">
              {cart.length > 0 && cart.length}
            </div>
            <FaCartPlus size={20} className="pb-1" color="#186F65" />
          </button>

          <button
            onClick={() => {
              navigate("/profile");
            }}
          >
            <CgProfile size={25} className="pb-1" color="#186F65" />
          </button>
          {!isUser && (
            <button
              onClick={() => {
                navigate("/register");
              }}
              className=" mx-3 w-[64px] h-[32px] gap-[12font-medium text-sm border border-[#186F65] text-[#186F65] hover:bg-[#186F65] hover:text-white transition-all duration-5s00"
            >
              Sign Up
            </button>
          )}
          {!isUser && (
            <button
              onClick={() => {
                navigate("/login");
              }}
              className=" w-[64px] h-[32px] gap-[12font-medium text-sm border bg-[#186F65] border-[#186F65] text-[#FFC800] hover:bg-[#186F65] hover:text-white transition-all duration-5s00"
            >
              Login
            </button>
          )}
        </div>
      </div>

      {}
    </div>
  );
}
