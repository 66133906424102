import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { PiCurrencyInrBold } from "react-icons/pi";
import usePurchase from "../../hooks/userPurchase";

export default function CartTotal() {
  const cart = useAppSelector((state) => state?.program.cart);

  const userId = useAppSelector((state) => state?.user.data?.id);
  const dispatch = useAppDispatch();
  const { createPurchase } = usePurchase();

  const [totlaPrice, setTotlaPrice] = useState<any>(0);
  const [discountPrice, setDiscountPrice] = useState<any>(0);
  const [exchange, setExchange] = useState<any>(0);
  const courseListIds = cart.map((item: any) => item.courseId);

  const getPushchaseCourses = (transactionId: any) => {
    createPurchase({
      userId,
      courseListIds,
      transactionId,
      amount: totlaPrice,
    });
  };

  const [isApply, setisApply] = useState<any>(false);

  const [coupon, setCoupon] = useState<any>("");
  useEffect(() => {
    calculatePriceAndDiscount();
  }, [cart]);

  const calculatePriceAndDiscount = () => {
    setTotlaPrice(getTotalValue());
    setDiscountPrice(getDiscountValue());
    setExchange(
      (
        ((getDiscountValue() - getTotalValue()) * 100) /
        getDiscountValue()
      ).toFixed(2)
    );
  };

  const getTotalValue = () => {
    return cart.reduce((total, item) => total + item.price, 0);
  };
  const getDiscountValue = () => {
    return cart.reduce((total, item) => total + item.discount, 0);
  };

  return (
    <div className="m-2">
      <div className="  bg-[#21496C] w-[400px] h-[548px] rounded-md p-4 py-2">
        <div className="font-poppins   font-normal text-xl leading-15 text-left text-white">
          Total
        </div>
        <div
          className="flex felx-row items-start
              justify-start "
        >
          <PiCurrencyInrBold size={30} color="#ffffff" />

          <div className="font-poppins text-2xl font-bold leading-15 text-left text-white">
            {totlaPrice}
          </div>
        </div>

        <div className="flex flex-row   justify-start">
          <div className="font-poppins text-base font-bold leading-15 text-center text-white">
            {exchange}%
          </div>
          <PiCurrencyInrBold
            size={14}
            color="#ffffff"
            opacity={30}
            className="mt-[2px] ml-2"
          />
          <div className="font-poppins text-sm font-bold leading-15 text-center text-white text-opacity-30 line-through">
            {discountPrice}
          </div>
        </div>

        <div className=" mx-4 my-10 border-solid bg-white h-[2px] "></div>

        <button className="w-[253px] h-[52px] bg-white flex flex-col justify-center items-center m-14">
          <div className="text-[#186F65] text-2xl font-medium">Checkout</div>
        </button>
        <div className="">
          <div
            className=" font-medium text-white
         text-xl "
          >
            Promotions
          </div>
          <div
            className=" font-medium text-white
         text-xl "
          >
            NVDPRODIN35 is applied
          </div>
          <div className="flex flex-row m-4 ">
            <input
              onChange={(e) => {
                setCoupon(e.target.value);
              }}
              value={coupon}
              className="px-1 mx-2 border-solid bg-white h-[40px] w-[200px] "
              type="text"
            />

            <div className="w-[140px] h-[32px] bg-white flex flex-col justify-center items-center rounded-full">
              <button
                onClick={() => {
                  if (!isApply) {
                    if (coupon == "NVDPRODIN35") {
                      setTotlaPrice(totlaPrice - 100);
                      setisApply(true);
                    } else {
                      alert("Coupon is not correct");
                    }
                  } else {
                    alert("Now you cant apply");
                  }
                }}
                className="text-[#186F65] text-sm font-medium"
              >
                Apply
              </button>
            </div>
          </div>
          <button
            onClick={() => {
              getPushchaseCourses("ijfiejirjilewrnewrnkjner");
            }}
            className="w-[253px] h-[52px] rounded-md bg-white flex flex-col justify-center items-center m-14 "
          >
            <div className="text-[#186F65] text-2xl font-medium">
              Purchase Now
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
