import React from "react";
import enroll from "../../../assets/photo7.png";

import { useNavigate } from "react-router-dom";

export default function EnrollTopBody() {
  const navigate = useNavigate();

  const onNagigation = () => {
    navigate("/enroll");
  };

  return (
    <div className="px-20 pt-20 ">
      <div className="flex flex-row justify-between ">
        <div className="flex items-start  justify-start">
          <img className="h-[580px] w-[487px]" src={enroll} alt="enroll" />
        </div>
        <div className="flex flex-col justify-start w-[557px] pt-16">
          <div className="pt-2 text-[#083156] font-bold text-[45px] ">
            Access to learning anytime and anywhere
          </div>
          <div className=" pt-6 text-[#083156] font-medium text-xs tracking-[2%] break-words">
            Discover easy trading methods, indicators, and live sessions at
            tealvue learn Hub. Elevate your trading journey with user-friendly
            tools and expert insights
          </div>
          <div className="flex flex-col items-center justify-center py-4  pr-[250px]">
            <div className="flex flex-row justify-center">
              {/* Quadrant 1 */}
              <div className="flex flex-col items-start justify-start p-5 relative">
                <div className="text-[#083156] font-semibold text-base tracking-[2%] break-words">
                  600+
                </div>
                <div className="font-medium text-xs tracking-[2%] break-words text-[#6A6B6C]">
                  Experience Teacher
                </div>
                <div className="absolute right-0 bottom-0 w-full h-full border-dashed border-r border-b border-[#083156]"></div>
              </div>

              {/* Quadrant 2 */}
              <div className="flex flex-col items-start justify-start p-5 relative">
                <div className="text-[#083156] font-semibold text-base tracking-[2%] break-words">
                  100+
                </div>
                <div className="font-medium text-xs tracking-[2%] break-words text-[#6A6B6C]">
                  Successful Students
                </div>
                <div className="absolute right-0 bottom-0 w-full h-full border-dashed border-b border-[#083156]"></div>
              </div>
            </div>

            <div className="flex flex-row justify-start">
              {/* Quadrant 3 */}
              <div className="flex flex-col items-start justify-start p-5 relative">
                <div className="text-[#083156] font-semibold text-base tracking-[2%] break-words">
                  70+
                </div>
                <div className="font-medium text-xs tracking-[2%] break-words text-[#6A6B6C]">
                  Expert Learners
                </div>
                <div className="absolute right-0 bottom-0 w-full h-full border-dashed border-r border-[#083156]"></div>
              </div>

              {/* Quadrant 4 */}
              <div className="flex flex-col items-start justify-start p-5 relative">
                <div className="text-[#083156] font-semibold text-base tracking-[2%] break-words">
                  80+
                </div>
                <div className="font-medium text-xs tracking-[2%] break-words text-[#6A6B6C]">
                  Happy Students
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={onNagigation}
            className="w-[150px]  flex items-center rounded-3xl
             justify-center h-[32px] gap-[12.16px] text-sm font-bold border bg-[#083156] border-[#083156] text-[#FFC800]   hover:bg-[#083156] hover:text-white transition-all duration-500"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
}
