import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { updateLogin } from "../redux/slices/user";
import { toast } from "react-toastify";
import {
  updateCourses,
  updatePaidCourserProgrss,
  updatePurchageCourses,
} from "../redux/slices/course";

function useProgress() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userAccessToken = useAppSelector((state) => state.user.token);

  const createProgress = async (payload: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/api/v1/progress/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      if (data.progress) {
        dispatch(
          updatePaidCourserProgrss({
            courseId: payload["courseId"],
            courseProgress: payload,
          })
        );
        toast.success("Unlock Successfully");
      }
      if (data.msg == "present") {
        toast.warning("Alreay  Unlock the Section");
      }
      return data;
    } catch (error) {
      toast.error("Unlock Failed");
      return error;
    }
  };

  const getProgressByCourse = async (payload: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/api/v1/progress/getbyCourse`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );

      console.log("data  in getProgressByCourse", data);

      return data;
    } catch (error) {
      toast.error("Failed login");
      return error;
    }
  };

  return {
    createProgress,
    getProgressByCourse,
  };
}

export default useProgress;
