import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProgramView {
  item: any;
}

export interface ProgramState {
  programDetails: ProgramView;
  cart: any[]; // Corrected the type of 'cart' to be an array
  currentButton: String;
}

export const initialProgramState: ProgramState = {
  programDetails: {
    item: {},
  },
  cart: [],
  currentButton: "",
};

export const program = createSlice({
  name: "program",
  initialState: initialProgramState,
  reducers: {
    updateCurrentButton: (state, action: PayloadAction<String>) => {
      state.currentButton = action.payload;
    },
    updateItem: (state, action: PayloadAction<any>) => {
      state.programDetails.item = action.payload;
    },
    addTocart: (state, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        cart: [...state.cart, payload],
      };
      // Corrected pushing the payload directly
    },
    removeFromCart: (state, action: PayloadAction<number>) => {
      console.log("trigger rerer", action.payload, state.cart.length);

      // Use filter to remove the item from the cart array
      state.cart = state.cart.filter((item, index) => index !== action.payload);
      // state.cart = state.cart.filter((item: any) => item.id !== action.payload);
    },
  },
});

export const { updateItem, addTocart, removeFromCart, updateCurrentButton } =
  program.actions;

export default program.reducer;
