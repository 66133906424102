import React from "react";
import partner from "../../../assets/partner.png";
import partner2 from "../../../assets/p2.png";
import partner3 from "../../../assets/p3.png";
import partner4 from "../../../assets/p4.png";
import partner5 from "../../../assets/p5.png";

export default function PartnerView() {
  return (
    <div className="px-20 ">
      <div className="flex flex-row justify-between">
        <img src={partner} alt={`partner `} />
        <img src={partner2} alt={`partner2 `} />
        <img src={partner3} alt={`partner3 `} />
        <img src={partner4} alt={`partner4 `} />
        <img src={partner5} alt={`partner5 `} />
      </div>
    </div>
  );
}
