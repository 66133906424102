import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { IoMdPerson } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa6";
import { BsCartPlus } from "react-icons/bs";
import lmsJson from "../../../lms.json";
import SearchField from "../../../components/searchafeild";
import { useDispatch } from "react-redux";
import { addTocart, updateItem } from "../../../redux/slices/program";
import courseh from "../../../assets/courseh.png";
import coursehh from "../../../assets/coursehh.png";
import { useAppSelector } from "../../../redux/hooks";
import useCourseContent from "../../../hooks/useCourseContent";
import { updateIscurrentCourseContentPaid } from "../../../redux/slices/course";
import CustomDropdownMenu from "../../../components/dropdown";

export default function EnrollCourse() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allCourses = useAppSelector((state) => state.course.courses);
  const { getCourseContentById } = useCourseContent();

  const [value, setValue] = React.useState<any>(4.9);
  const [hover, setHover] = React.useState<any>(-1);
  const [lmsData, setLmsData] = React.useState<any>(allCourses);
  const [lmsDataFilter, setLmsDtaFilter] = React.useState<any>(allCourses);

  const [currentButton, setCurrentButton] =
    React.useState<any>("coursesLibrary");
  const [currentButtonFilter, setCurrentButtonFilter] =
    React.useState<any>("courses library");
  const courseContentData = async (contentId: any) => {
    await getCourseContentById(contentId);
  };

  const [inputValue, setInputValue] = React.useState<any>("");

  const [category, setCategory] = React.useState<any>("select");
  const handleTextChange = (newValue: any) => {
    setInputValue(newValue);
  };

  const handleDropdownCategory = (value: any) => {
    setCategory(value);
  };

  const labels: any = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  function getLabelText(value: any) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  // Function to filter the data based on courseName
  const filterData = (searchTerm: any) => {
    const filteredData = lmsData.filter((item: any) =>
      item.courserName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setLmsDtaFilter(filteredData);
  };

  // Function to filter the data based on courseName
  const filterDataType = (searchTerm: any) => {
    const filteredData = lmsData.filter((item: any) =>
      item.type.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setLmsDtaFilter(filteredData);
  };

  useEffect(() => {
    filterData(inputValue);
  }, [inputValue]);

  useEffect(() => {
    setLmsData(allCourses);
    setCurrentButtonFilter("coursesLibrary");
    filterDataType("coursesLibrary");
  }, [allCourses]);

  const renderContainer = () => {
    const containers: any = [];

    lmsDataFilter.map((item: any, i: any) => {
      // Added parentheses here
      containers.push(
        <div>
          <div
            key={i}
            className="relative bg-white rounded-xl flex flex-col justify-start"
          >
            <button
              onClick={() => {
                dispatch(updateItem(item));
                courseContentData({ courseId: item.courseId });
                dispatch(updateIscurrentCourseContentPaid(false));

                if (item.type === "bundle") {
                  navigate("/bundle");
                } else if (item.type === "blog") {
                  navigate("/blog");
                } else if (item.type === "webinar") {
                  navigate("/webinar");
                } else {
                  navigate("/details");
                }
              }}
              className="flex flex-row justify-center"
            >
              {(item.type == "coursesLibrary" ||
                item.type == "free" ||
                item.type == "blog") && (
                <img
                  className="h-[170px] w-[320px] p-1"
                  src={item.url}
                  alt="enroll "
                />
              )}

              {item.type == "bundle" && (
                <img
                  className="h-[200px] w-[411px] p-1"
                  src={item.url}
                  alt="enroll "
                />
              )}
              {item.type == "webinar" && (
                <img
                  className="h-[200px] w-[411px] p-1"
                  src={item.url}
                  alt="enroll "
                />
              )}
            </button>

            {(item.type == "coursesLibrary" || item.type == "free") && (
              <div className="flex flex-row justify-between gap-2">
                <div className="font-poppins py-1 pl-4 text-xs font-semibold leading-15 text-left text-[#827A7A]">
                  {item.lession} lessons
                </div>
                <div className="font-poppins py-1 pr-2 pl-4 text-xs font-semibold leading-15 text-left text-[#827A7A]">
                  Course ID : {item.courseId.substring(0, 10)}
                </div>
              </div>
            )}

            {(item.type == "coursesLibrary" || item.type == "free") && (
              <div className="font-poppins py-1 pl-4 text-xl font-semibold leading-15 text-left">
                {item.courserName}
              </div>
            )}
            {item.type == "blog" && (
              <div className="px-2">
                <div className="font-poppins py-1 w-[282px]  font-semibold text-base  text-[#186F65]">
                  How To Identify Good Stocks [2024]: Explained For Beginners
                </div>
                <div className="font-poppins py-1 w-[282px]   font-normal text-xs  text-[#B5CB99]">
                  Starting the journey of stock investing presents a crucial
                  decision for beginners. Either one can rely on expensive
                  expert advice
                </div>
              </div>
            )}

            {(item.type == "coursesLibrary" || item.type == "free") && (
              <div className="flex flex-row justify-between pl-4">
                <div className="flex flex-row justify-start">
                  <div className="font-poppins py-1 text-sm font-semibold leading-15 text-left">
                    {item.price}
                  </div>
                  <div className="font-poppins py-1 pl-1 mt-1 items-baseline text-xs font-semibold leading-15 text-left text-[#827A7A] line-through">
                    {item.discount}
                  </div>
                </div>
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="hover-feedback"
                    value={item.rating}
                    precision={0.5}
                    getLabelText={getLabelText}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {item.rating !== null && (
                    <Box sx={{ ml: 2 }}>
                      {labels[hover !== -1 ? hover : item.rating]}
                    </Box>
                  )}
                </Box>

                <div className="font-poppins py-1 pl-1 pr-1 items-baseline text-xs font-semibold leading-15 text-left text-[#827A7A]">
                  {item.rating} Rating
                </div>
              </div>
            )}
            {item.type == "coursesLibrary" && (
              <div>
                <div className="border-t-2 m-4 border-solid border-[#6A6B6C] w-10%"></div>

                <div className="flex felx-row  justify-between  py-3 px-4">
                  <div className="flex felx-row  justify-start ">
                    <IoMdPerson color="#6A6B6C" />
                    <div className="font-poppins pl-2 text-sm font-semibold leading-15 text-left text-[#827A7A]">
                      {item.people} Student
                    </div>
                  </div>
                  {/* <button
              onClick={() => {}}
              className="px-4 flex items-center justify-center h-[28px] gap-[12.16px] text-sm font-bold border bg-[#083156] border-[#083156] text-[#FFC800] rounded hover:bg-[#083156] hover:text-white transition-all duration-500"
            >
              <FaArrowRight color="#FFFFFF" size={18} />
            </button> */}
                  <button
                    onClick={() => {
                      dispatch(addTocart(item));
                    }}
                    className="px-4 flex flex-row items-center justify-center h-[28px] gap-[12.16px] text-sm font-bold border bg-[#083156] border-[#083156] text-white rounded hover:bg-[#083156] hover:text-white transition-all duration-500"
                  >
                    <div>Add to Cart</div>
                    <BsCartPlus color="#FFFFFF" size={18} />
                  </button>
                </div>
              </div>
            )}
            {item.type == "free" && (
              <div>
                <div className="border-t-2 m-4 border-solid border-[#6A6B6C] w-10%"></div>

                <div className="flex felx-row  justify-between  py-3 px-4">
                  <div className="flex felx-row  justify-start ">
                    <IoMdPerson color="#6A6B6C" />
                    <div className="font-poppins pl-2 text-sm font-semibold leading-15 text-left text-[#827A7A]">
                      {item.people} Student
                    </div>
                  </div>

                  <button
                    onClick={() => {}}
                    className="px-4 flex flex-row items-center justify-center h-[28px] gap-[12.16px] text-sm font-bold border bg-[#083156] border-[#083156] text-white rounded hover:bg-[#083156] hover:text-white transition-all duration-500"
                  >
                    <div>Free</div>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    });

    return containers;
  };

  return (
    <div>
      <div className="pt-5">
        <div className=" px-20 flex flex-row justify-between pt-32 py-20">
          <div>
            <div>
              <img className=" " src={courseh} alt="courseh" />
              <div className="font-normal text-base text-[#186F65] w-[667px]">
                A collection of over 800 unique ready-made courses, authored by
                experts, covering the skills your teams need for success at work
              </div>
            </div>
          </div>
          <div className="relative inline-block">
            <svg className="absolute top-0 left-0" width="300" height="300">
              <circle cx="150" cy="150" r="107" fill="#FFEFBF" />
            </svg>

            <img
              className="relative"
              width={515}
              src={coursehh}
              alt="coursehh"
            />
          </div>
        </div>

        <div className="px-20 mt-2 bg-white h-[170px] w-full py-4 flex flex-col justify-start">
          <div className="py-4 pl-4 text-left text-[#083156] font-bold text-[27px]">
            New courses released every quarter
          </div>

          <div className="flex flex-row justify-between">
            <SearchField
              value={inputValue}
              onChangeCallback={handleTextChange}
            />
            <div className=" flex flex-row justify-end gap-x-8">
              <button
                onClick={() => {
                  setCurrentButton("Course");
                  filterData("");
                }}
              >
                <div
                  className={
                    currentButton === "Course"
                      ? "bg-[#1F736A] w-[200px] h-[40px] flex items-center justify-center"
                      : "border-[#1F736A] border-2 w-[150px] h-[40px] flex items-center justify-center"
                  }
                >
                  <div
                    className={
                      currentButton === "Course"
                        ? "text-white"
                        : "text-[#1F736A]"
                    }
                  >
                    Course
                  </div>
                </div>
              </button>

              <button
                onClick={() => {
                  setCurrentButton("Bundle");
                  filterDataType("bundle");
                }}
              >
                <div
                  className={
                    currentButton === "Bundle"
                      ? "bg-[#1F736A] w-[200px] h-[40px] flex items-center justify-center"
                      : "border-[#1F736A] border-2 w-[150px] h-[40px] flex items-center justify-center"
                  }
                >
                  <div
                    className={
                      currentButton === "Bundle"
                        ? "text-white"
                        : "text-[#1F736A]"
                    }
                  >
                    Bundle
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className=" flex flex-row justify-center gap-x-4 pt-10">
          <button
            onClick={() => {
              setCurrentButtonFilter("coursesLibrary");
              filterDataType("coursesLibrary");
              // setCurrentButtonFilter("coursesLibrary");
              // filterData("courses");
            }}
          >
            <div
              className={
                currentButtonFilter === "coursesLibrary"
                  ? "    border-2 border-[#FFC800]  px-2 h-[40px] flex items-center justify-center"
                  : "border-[#1F736A] border-2  px-2 h-[40px] flex items-center justify-center"
              }
            >
              <div
                className={
                  currentButtonFilter === "courses library"
                    ? "text-[#FFC800]"
                    : "text-[#1F736A]"
                }
              >
                courses library
              </div>
            </div>
          </button>
          <button
            onClick={() => {
              setCurrentButtonFilter("bundle");
              filterDataType("bundle");
            }}
          >
            <div
              className={
                currentButtonFilter === "bundle"
                  ? "    border-2 border-[#FFC800]  px-2 h-[40px] flex items-center justify-center"
                  : "border-[#1F736A] border-2  px-2 h-[40px] flex items-center justify-center"
              }
            >
              <div
                className={
                  currentButtonFilter === "bundle"
                    ? "text-[#FFC800]"
                    : "text-[#1F736A]"
                }
              >
                Bundle
              </div>
            </div>
          </button>
          <button
            onClick={() => {
              setCurrentButtonFilter("free");
              filterDataType("free");
            }}
          >
            <div
              className={
                currentButtonFilter === "free"
                  ? "    border-2 border-[#FFC800]  px-2 h-[40px] flex items-center justify-center"
                  : "border-[#1F736A] border-2  px-2 h-[40px] flex items-center justify-center"
              }
            >
              <div
                className={
                  currentButtonFilter === "free"
                    ? "text-[#FFC800]"
                    : "text-[#1F736A]"
                }
              >
                Free Library
              </div>
            </div>
          </button>
          {/* <button
            onClick={() => {
              setCurrentButtonFilter("program");
              filterDataType("program");
            }}
          >
            <div
              className={
                currentButtonFilter === "program"
                  ? "    border-2 border-[#FFC800]  px-2 h-[40px] flex items-center justify-center"
                  : "border-[#1F736A] border-2  px-2 h-[40px] flex items-center justify-center"
              }
            >
              <div
                className={
                  currentButtonFilter === "program"
                    ? "text-[#FFC800]"
                    : "text-[#1F736A]"
                }
              >
                Program
              </div>
            </div>
          </button>
          */}

          <button
            onClick={() => {
              setCurrentButtonFilter("webinar");
              filterDataType("webinar");
            }}
          >
            <div
              className={
                currentButtonFilter === "webinar"
                  ? "    border-2 border-[#FFC800]  px-2 h-[40px] flex items-center justify-center"
                  : "border-[#1F736A] border-2  px-2 h-[40px] flex items-center justify-center"
              }
            >
              <div
                className={
                  currentButtonFilter === "webinar"
                    ? "text-[#FFC800]"
                    : "text-[#1F736A]"
                }
              >
                Webinar
              </div>
            </div>
          </button>
          <button
            onClick={() => {
              setCurrentButtonFilter("blog");
              filterDataType("blog");
            }}
          >
            <div
              className={
                currentButtonFilter === "blog"
                  ? "    border-2 border-[#FFC800]  px-2 h-[40px] flex items-center justify-center"
                  : "border-[#1F736A] border-2  px-2 h-[40px] flex items-center justify-center"
              }
            >
              <div
                className={
                  currentButtonFilter === "blog"
                    ? "text-[#FFC800]"
                    : "text-[#1F736A]"
                }
              >
                Blog
              </div>
            </div>
          </button>
        </div>
        <div className="flex flex-row justify-between  px-20 py-4">
          <div className="text-[27px] font-bold">
            {currentButton == "coursesLibrary" && "All Courses "}
            {currentButton == "webinar" && "webinar"}
            {currentButton == "free" && "Free Library "}
          </div>

          <div>
            <CustomDropdownMenu
              width={"150px"}
              options={[
                { value: "Web ", label: "web" },
                { value: "stock ", label: "stock" },
              ]}
              selectedValue={"Select"}
              onValueChange={handleDropdownCategory}
            />
          </div>
        </div>

        <div className="flex justify-center items-center flex-wrap gap-x-4 gap-y-2 pt-4">
          {renderContainer()}
          {currentButtonFilter === "bundle" && (
            <div className="px-20">
              <div className="flex justify-center items-center font-extrabold text-2xl py-20 text-[#186F65]">
                Finally, courses that everyone will show up for
              </div>

              <div className="flex flex-row justify-between gap-x-10 ">
                <div>
                  <div className=" font-medium text-lg text-[#186F65]">
                    Courses are no longer than 15’
                  </div>
                  <div className=" font-normal text-sm text-[#B5CB99]">
                    Each course is made up of mini-lessons between 2-3 minutes
                    each. Making information easily digestible reduces cognitive
                    load so users are more likely to complete training, enjoy
                    it, and use it at work.
                  </div>
                </div>
                <div>
                  <div className=" font-medium text-lg text-[#186F65]">
                    Courses are no longer than 15’
                  </div>
                  <div className=" font-normal text-sm text-[#B5CB99]">
                    Each course is made up of mini-lessons between 2-3 minutes
                    each. Making information easily digestible reduces cognitive
                    load so users are more likely to complete training, enjoy
                    it, and use it at work.
                  </div>
                </div>{" "}
                <div>
                  <div className=" font-medium text-lg text-[#186F65]">
                    Courses are no longer than 15’
                  </div>
                  <div className=" font-normal text-sm text-[#B5CB99]">
                    Each course is made up of mini-lessons between 2-3 minutes
                    each. Making information easily digestible reduces cognitive
                    load so users are more likely to complete training, enjoy
                    it, and use it at work.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {currentButtonFilter !== "bundle" && (
          <div className="flex px-20   flex-row items-center  justify-center py-14 ">
            <button
              // onClick={onNagigation}
              className="w-[150px]  flex items-center rounded-xl text-white
             justify-center  h-[32px] gap-[12.16px] text-sm font-bold border bg-[#29A354] border-[#29A354] text-whitehover:bg-[#083156] hover:text-white transition-all duration-500"
            >
              View More
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
