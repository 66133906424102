import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { updateLogin } from "../redux/slices/user";
import { toast } from "react-toastify";
import { updateCourseContent } from "../redux/slices/course";

function useCourseContent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userAccessToken = useAppSelector((state) => state.user.token);

  const createCourseContent = async (userData: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/api/v1/courseContent/create`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      if (data) {
        toast.success("Course Content  Created Successfully");
      }
      return data;
    } catch (error) {
      toast.error("Failed");
      return error;
    }
  };

  //?
  const getCourseContentById = async (payload: any) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_hostName}/api/v1/courseContent/getbyId`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Access-Control-Allow-Origin": "*", // Set the allowed origin
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE", // Set the allowed HTTP methods
            "Access-Control-Allow-Headers": "Content-Type", // Set the allowed headers
          },
        }
      );
      if (data.courseContent) {
        dispatch(updateCourseContent(data.courseContent));
      }

      return data;
    } catch (error) {
      toast.error("Failed To get Content");
      return error;
    }
  };

  return {
    createCourseContent,
    getCourseContentById,
  };
}

export default useCourseContent;
