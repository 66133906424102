import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { LuMail } from "react-icons/lu";
import { IoMdTime } from "react-icons/io";

export default function EditAccout() {
  return (
    <div>
      <div>
        <div
          className="flex flex-row justify-between pt-16
         "
        >
          <div className=" p-2 gap-x-10 flex flex-row justify-between border-[1px] border-gray-400 h-[60px]  ">
            <div>
              <div className="font-medium text-sm text-[#6B6B6B]">
                Contact us
              </div>
              <div className="flex flex-row justify-start text-center items-center gap-1">
                <div>
                  <FiPhoneCall />
                </div>
                <div className="font-semibold text-[15px] text-[#000000]">
                  +917695996747
                </div>
              </div>
              <div></div>
            </div>
            <div>
              <div className="font-medium text-sm text-[#6B6B6B]">
                Customer Service
              </div>
              <div className="flex flex-row justify-start text-center items-center gap-1">
                <div>
                  <FiPhoneCall />
                </div>
                <div className="font-semibold text-[15px] text-[#000000]">
                  +917695996747
                </div>
              </div>
              <div></div>
            </div>

            <div>
              <div className="font-medium text-sm text-[#6B6B6B]">
                Support mail
              </div>
              <div className="flex flex-row justify-start text-center items-center gap-1">
                <div>
                  <LuMail />
                </div>
                <div className="font-semibold text-[15px] text-[#000000]">
                  info@flamingo.co.in
                </div>
              </div>
              <div></div>
            </div>

            <div>
              <div className="font-medium text-sm text-[#6B6B6B]">Time</div>
              <div className="flex flex-row justify-start text-center items-center gap-1">
                <div>
                  <IoMdTime />
                </div>
                <div className="font-semibold text-[15px] text-[#000000]">
                  9AM - 6PM
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <div className="w-3/5   ">
            <div className="flex flex-row justify-between my-10 mx-5">
              <div className=" w-1/4font-medium text-sm">Full Name</div>
              <div className="w-3/4   border-gray-600 mr-10">
                <input
                  type="text"
                  id="inputField"
                  placeholder="Enter Full Name"
                  style={{
                    backgroundColor: "transparent",
                    width: "100%",
                    border: "none",
                    borderBottom: "1px solid #000", // You can change the color here
                    outline: "none", // Remove the default focus border
                  }}
                ></input>
              </div>
            </div>
            <div className="font-semibold text-sm text-[#5F5F5F] mx-5">
              Password Update
            </div>
            <div className="flex flex-row justify-between my-10 mx-5">
              <div className=" w-1/4font-medium text-sm">Old Password</div>
              <div className="w-3/4   border-gray-600 mr-10">
                <input
                  type="password"
                  id="inputField"
                  placeholder="**********"
                  style={{
                    backgroundColor: "transparent",
                    width: "100%",
                    border: "none",
                    borderBottom: "1px solid #000", // You can change the color here
                    outline: "none", // Remove the default focus border
                  }}
                ></input>
              </div>
            </div>
            <div className="flex flex-row justify-between my-10 mx-5">
              <div className=" w-1/4font-medium text-sm">New Password</div>
              <div className="w-3/4   border-gray-600 mr-10">
                <input
                  type="password"
                  id="inputField"
                  placeholder="**********"
                  style={{
                    backgroundColor: "transparent",
                    width: "100%",
                    border: "none",
                    borderBottom: "1px solid #000", // You can change the color here
                    outline: "none", // Remove the default focus border
                  }}
                ></input>
              </div>
            </div>
            <div className="flex flex-row justify-between my-10 mx-5">
              <div className=" w-1/4font-medium text-sm">Re-Enter Password</div>
              <div className="w-3/4   border-gray-600 mr-10">
                <input
                  type="password"
                  id="inputField"
                  placeholder="**********"
                  style={{
                    backgroundColor: "transparent",
                    width: "100%",
                    border: "none",
                    borderBottom: "1px solid #000", // You can change the color here
                    outline: "none", // Remove the default focus border
                  }}
                ></input>
              </div>
            </div>

            <div className=" flex flex-row justify-end my-10 mx-16">
              <div className=" p-2 px-16 bg-blue-800 text-white text-center items-end">
                Update
              </div>
            </div>
          </div> */
}
