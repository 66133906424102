import React, { useState } from "react";
import logoh from "../../assets/logoh.png";
import { useNavigate } from "react-router-dom";
import useProfile from "../../hooks/userProfile";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loginUser } = useProfile();

  const loginUserAuth = async () => {
    const payload = {
      email: email,
      password: password,
    };
    await loginUser(payload);
  };

  return (
    <div className="bg-[#FFFAED]  py-20 h-screen flex flex-col justify-center items-center">
      <img src={logoh} alt="logo" />
      <div className="font-semibold text-2xl text-[#555555] ">
        Login into your account
      </div>
      <div className="flex flex-col w-[380px]  items-center gap-4">
        <TextField
          label="Email :"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />
        <TextField
          label="Password :"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
      </div>
      <button
        onClick={() => {
          navigate("/forgotPassword");
        }}
        className="flex justify-end w-[380px]"
      >
        <div className="font-normal text-[12.5px] text-[#FF725E]">
          Forgot password?
        </div>
      </button>
      <button
        onClick={() => {
          loginUserAuth();
        }}
        className="bg-[#FF725E] rounded-md h-[40px] w-[380px] mt-10 text-center font-semibold text-sm text-white"
      >
        Login Now
      </button>
      <hr className=" border-t border-gray-300 my-4 w-[380px]" />
      {/* <div className=" border-t border-gray-300 my-4 w-[380px]"> or</div> */}

      <button
        onClick={() => {
          navigate("/register");
        }}
        className=" rounded-md h-[40px] w-[380px] mt-4 border-2 border-[#FF725E] text-center font-semibold text-sm text-[#FF725E] "
      >
        Signup now
      </button>
    </div>
  );
}

function TextField({ label, value, onChange }: any) {
  return (
    <div className="flex flex-col w-full  ">
      <div className="font-normal text-sm">{label}</div>
      <input
        type="text"
        className="w-full h-12 md:h-[43px] bg-gray-200 rounded-md px-2"
        style={{ backgroundColor: "#EEEEEE" }}
        placeholder={`Enter your ${label.replace(" :", "")}`}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
